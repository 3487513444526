import React, { useEffect } from 'react';
import './TermsNconditions.css';

function TermsNconditions() {

    useEffect(()=> window.scrollTo(0, 0), []);

  return (
    <div className='container my-5'>
        <h2 className="body-font-bold mb-4 fs-3">Terms & Conditions</h2>
        <p className="mb-4">
            Welcome to HOMEJOY. These Terms and Conditions govern your use of our website, <a href="homejoy.netlify.app" target='_blank' className="nav-link d-inline text-primary">www.homejoy.com</a>, and the purchase of any products from our online store. By accessing or using our website, you agree to comply with and be bound by these Terms and Conditions
        </p>
        <div className="content">
            <ol className='m-0'>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">General Use</p>
                    <ol className='m-0' type='I'>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Eligibility:</span> By using this website, you confirm that you are at least 18 years old or accessing the site under the supervision of a parent or legal guardian.
                            </p>
                        </li>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Account:</span> You may need to create an account to purchase products. You are responsible for maintaining the confidentiality of your account details and for all activities under your account.
                            </p>
                        </li>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Prohibited Actions:</span> You agree not to misuse the website for unlawful purposes, distribute viruses, or attempt to hack the site.
                            </p>
                        </li>
                    </ol>
                </li>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">Product Information</p>
                    <ol className='m-0' type='I'>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Accuracy:</span> We strive to ensure that all product descriptions, images, and prices are accurate. However, slight variations in color or design may occur due to differences in display settings or manufacturing processes.
                            </p>
                        </li>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Availability:</span> All products are subject to availability. We reserve the right to limit the quantity of any products or discontinue any product at any time.
                            </p>
                        </li>
                    </ol>
                </li>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">Orders and Payment</p>
                    <ol className='m-0' type='I'>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Order Acceptance:</span> Placing an order does not constitute a binding contract until we have accepted and confirmed your order. We reserve the right to refuse or cancel orders at our discretion.
                            </p>
                        </li>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Pricing:</span> Prices are listed in INR (Indian Rupees ₹) and are subject to change without notice. You will be charged the price displayed at the time of your purchase.
                            </p>
                        </li>
                    </ol>
                </li>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">Shipping and Delivery</p>
                    <ol className='m-0' type='I'>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Shipping:</span> Shipping costs are calculated at checkout and are based on your location and order size. Delivery times are estimates and may vary based on your location and shipping method.
                            </p>
                        </li>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Risk of Loss:</span> All items purchased from HOMEJOY are made pursuant to a shipment contract. The risk of loss and title for such items pass to you upon delivery to the carrier.
                            </p>
                        </li>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Customs and Duties:</span> If you are ordering from outside of India, you may be subject to import duties and taxes, which are your responsibility.
                            </p>
                        </li>
                    </ol>
                </li>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">Returns and Refunds</p>
                    <ol className='m-0' type='I'>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Return Policy:</span> You may return unworn, unused items in their original condition within 5 days of receipt. Please refer to our Return Policy for more details.
                            </p>
                        </li>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Refunds:</span> Refunds will be issued to your original payment method within 2 days of receiving your return. Shipping fees are non-refundable.
                            </p>
                        </li>
                    </ol>
                </li>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">Intellectual Property</p>
                    <ol className='m-0' type='I'>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Ownership:</span> All content on this website, including images, text, graphics, logos, and software, is the property of HOMEJOY and is protected by intellectual property laws.
                            </p>
                        </li>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Use:</span> You may not reproduce, distribute, or use any content from this website for commercial purposes without our express written consent.
                            </p>
                        </li>
                    </ol>
                </li>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">Limitation of Liability</p>
                    <ol className='m-0' type='I'>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>No Warranty:</span> HOMEJOY makes no warranties, expressed or implied, about the accuracy or reliability of the content on this website or the products sold.
                            </p>
                        </li>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Liability:</span> To the maximum extent permitted by law, HOMEJOY is not liable for any indirect, incidental, or consequential damages arising from your use of the website or purchase of products.
                            </p>
                        </li>
                    </ol>
                </li>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">Governing Law</p>
                    <p className="text-muted">
                        These Terms and Conditions are governed by and construed in accordance with the laws of Maharastra, India, without regard to its conflict of law principles. Any legal actions related to these Terms shall be brought in the courts of India.
                    </p>
                </li>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">Changes to Terms</p>
                    <p className="text-muted">
                        We reserve the right to modify these Terms and Conditions at any time. Any changes will be effective immediately upon posting on the website. Your continued use of the website constitutes your acceptance of the updated Terms.
                    </p>
                </li>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">Contact Information</p>
                    <p className="text-muted">
                        If you have any questions or concerns regarding these Terms and Conditions, please contact us at:
                    </p>
                    <p className="mb-0">HOMEJOY Customer Service</p>
                    <p className="mb-0">Email: <a href="mailto:email@gmail.com" className="nav-link d-inline text-primary">email@gmail.com</a></p>
                    <p className="mb-0">Phone no.: <a href="tel:+910987654321" className="nav-link d-inline text-primary">+91 09876 54321</a></p>
                </li>
            </ol>
        </div>
    </div>
  )
}

export default TermsNconditions