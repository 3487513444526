import React, { useState, useEffect } from 'react';
import './Alladmins.css';

function Alladmins() {

    const [alladmins, setAlladmins] = useState([]);

    useEffect(()=> {
        fetch(`https://homejoy.onrender.com/alladmins`).then(res => res.json()).then(data => {
            if(data.success){
                setAlladmins(data.pro);
            }else{
                console.log(data);
            }
        }).catch(err => console.log('error occured while getting all the admins ', err));
    }, []);

  return (
    <div className='main-admins'>
        <div className="container-fluid">
            <h2 className="body-font-bold fs-3 mt-3">All Admins</h2> 
            <div className="row">
                {
                    alladmins?.map((pro, ind) => (
                        <div key={ind} className="col-md-4 p-2 m-0 mb-4">
                            <div className="bg-brand-light rounded-brand-sm p-2 d-flex justify-content-between align-items-center">
                                <p className="mb-0 body-font-medium"><i className="fas fa-user me-2 text-muted"></i>{pro.username}</p>
                                <p className="mb-0 body-font-medium"><i className="fas fa-key me-2 text-muted"></i>{pro.password}</p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div> 
    </div>
  )
}

export default Alladmins