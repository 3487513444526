import React, { useEffect, useState } from 'react';
import './Dashstats.css';
import { Link } from 'react-router-dom';
import Tablecard from '../../Components/Tablecard/Tablecard';

function Dashstats() {

    const [allpro, setAllpro] = useState([]);
    const [allforord, setAllforord] = useState([]);
    const [allamaz, setAllamaz] = useState([]);
    const [allwhat, setAllwhat] = useState([]);
    const [allwebs, setAllwebs] = useState([]);
    const [mostlovedpro, setMostlovedpro] = useState([]);
    const [allorders, setAllorders] = useState([]);
    const [allorderpros, setAllorderpros] = useState([]);
    const [totalsale, setTotalsale] = useState(0);
    const [mostobj, setMostobj] = useState({});
    const [mostviewed, setMostviewed] = useState([]);
    const [allprowithnum, setAllprowithnum] = useState([]);

    useEffect(() => window.scrollTo(0, 0), []);

    useEffect(()=> {
        fetch(`https://homejoy.onrender.com`).then(res => res.json()).then(data => {
            console.log(data);
            if(data.success){
                setAllpro(data.pro);
                const mostviews = data.pro.sort((a, b) => b.views.length - a.views.length).slice(0, 10);
                setMostviewed(mostviews);
            }
        }).catch(err => console.log('error occured while getting all the product ', err));
    }, []);
    useEffect(() => {
        fetch(`https://homejoy.onrender.com/allorders`).then(res => res.json()).then(data => {
            if(data.success){
                setAllorders(data.pro);
            }else{
                console.log(data);
            }
        }).catch(err => console.log('error occured while getting all the orders ', err));
    }, []);
    useEffect(() => {
        fetch(`https://homejoy.onrender.com/leads`).then(res => res.json()).then(data => {
            if(data.success){
                setAllwebs(data.pro)
                console.log(data);
            }else{
                console.log('success is not true ', data);
            }
        }).catch(err => console.log("error occured while getting all the leads ", err));
    }, []);
    useEffect(()=> {
        fetch(`https://homejoy.onrender.com/fororders`).then(res => res.json()).then(data => {
            console.log(data);
            if(data.success){
                setAllforord(data.pro);
            }
        }).catch(err => console.log('error occured while getting all the product ', err));
    }, []);
    useEffect(() => {
        setAllamaz(allforord.filter(e => e.platform.toLowerCase() === 'amazon'));
        setAllwhat(allforord.filter(e => e.platform.toLowerCase() === 'whatsapp'));
    }, [allforord]);

    useEffect(() => {
        const allids = allforord?.map(pro => pro.productid);
        // const allids = [];
        // const webleads = allwebs?.map(pro => pro.proid);
        const someArr = [];
        allorders.forEach(ord => {
            const something = ord?.products.flatMap(product =>
                Array(product.quantity).fill(product.proid)
            );
            someArr.push(...something);
        })
        allids.push(...someArr);
        setAllprowithnum([...someArr]);
        const frequencymap = {};
        let maxcount = 0;
        let mostfrequentmap = null;
        allids.forEach(pro => {
            frequencymap[pro] = (frequencymap[pro] || 0) + 1;
            if(frequencymap[pro] > maxcount){
                maxcount = frequencymap[pro];
                mostfrequentmap = pro;
            }
        });
        // console.log(frequencymap);
        const sortedpros = Object.entries(frequencymap).sort((a,b) => b[1] - a[1]).slice(0, 10).map(pro => pro[0]);
        console.log(mostfrequentmap);
        setMostobj(frequencymap)
        // console.log(frequencymap)
        // console.log(sortedpros);
        const topThreepros = async () => {
            const topthreepros = await Promise.all(sortedpros?.map(pro => fetch(`https://homejoy.onrender.com/pro/${pro}`).then(res => res.json()).then(data => data.pro))).catch(err => console.log('error occured while getting the most loved product ', err));
            setMostlovedpro(topthreepros);
        };
        topThreepros();
    }, [allforord, allwebs, allorders])

    // useEffect(() => console.log(mostlovedpro), [mostlovedpro]);
    // useEffect(() => console.log('this is the most obj', mostobj), [mostobj]);

    useEffect(() => {
        if(allprowithnum && allprowithnum?.length > 0){
            // const allorderproids = [];
            // allorders.forEach(ord => {
            //     console.log(ord)
            //     ord.products.forEach((pro, ind) => {
            //         allorderproids.push(pro.proid);
            //     })
            // });
            // console.log('allorderproids', allorderproids);

            // const getallpros = async () => {
            //     const allpros = await Promise.all(allprowithnum?.map(id => fetch(`https://homejoy.onrender.com/pro/${id}`).then(res => res.json()).then(data => data.pro))).catch(err => console.log("error occured while getting all the pros for price ", err));
            //     setAllorderpros(allpros);
            // }

            const ordpros = [];
            allprowithnum?.forEach(id => {
                allpro?.forEach(pro => {
                    if(id === pro.proid){
                        ordpros.push(pro);
                    }
                })
            });
            setAllorderpros(ordpros);

            // getallpros();
        }
    }, [allprowithnum, allpro]);

    useEffect(() => {
        if(allorderpros && allorderpros?.length > 0){
            let pricecount = 0;
            allorderpros.forEach((pro, ind) => {
                pricecount += pro.discountprice;
                // console.log(pro.discountprice, ind);
            });
            // console.log('this is the total sales price ',pricecount);
            setTotalsale(pricecount);
        }
    }, [allorderpros])

    const splitFunc = (str)=> {
        const imgFst = str.split(',');
        return imgFst;
    }

  return (
    <div className='main-dashstats'>
        <div className="container-fluid">
            <h2 className="body-font-bold fs-3 mt-3">Dashboard</h2>
            <div className="row p-0 m-0">
                <div className="col-md-3 col-6 p-2">
                    <Link to='products' className='nav-link'>
                        <div className="bg-brand-light rounded-brand py-3 px-3">
                            <div className="d-flex align-items-center">
                                <p className="fs-1 mb-0 lh-sm me-3"><i className="fas fa-box"></i></p>
                                <div>
                                    <p className="mb-0 lh-sm fs-4 body-font-semibold">{allpro?.length}</p>
                                    <p className="stat-txt mb-0 lh-sm body-font-bold text-muted">PRODUCTS</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-md-3 col-6 p-2">
                    <Link to="leads" className="nav-link">
                        <div className="bg-brand-light rounded-brand py-3 px-3">
                            <div className="d-flex align-items-center">
                                <p className="fs-1 mb-0 lh-sm me-3"><i className="fas fa-globe"></i></p>
                                <div>
                                    <p className="mb-0 lh-sm fs-4 body-font-semibold">{allwebs?.length}</p>
                                    <p className="stat-txt mb-0 lh-sm body-font-bold text-muted">WEBSITE</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-md-3 col-6 p-2">
                    <Link to="leads" className="nav-link">
                        <div className="bg-brand-light rounded-brand py-3 px-3">
                            <div className="d-flex align-items-center">
                                <p className="fs-1 mb-0 lh-sm me-3"><i className="fab fa-amazon"></i></p>
                                <div>
                                    <p className="mb-0 lh-sm fs-4 body-font-semibold">{allamaz?.length}</p>
                                    <p className="stat-txt mb-0 lh-sm body-font-bold text-muted">AMAZON</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-md-3 col-6 p-2">
                    <Link to="leads" className="nav-link">
                        <div className="bg-brand-light rounded-brand py-3 px-3">
                            <div className="d-flex align-items-center">
                                <p className="fs-1 mb-0 lh-sm me-3"><i className="fab fa-whatsapp"></i></p>
                                <div>
                                    <p className="mb-0 lh-sm fs-4 body-font-semibold">{allwhat?.length}</p>
                                    <p className="stat-txt mb-0 lh-sm body-font-bold text-muted">WHATSAPP</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-md-3 col-6 p-2">
                    <Link to="leads" className="nav-link">
                        <div className="bg-brand-light rounded-brand py-3 px-3">
                            <div className="d-flex align-items-center">
                                <p className="fs-1 mb-0 lh-sm me-3"><i className="fas fa-magnet"></i></p>
                                <div>
                                    <p className="mb-0 lh-sm fs-4 body-font-semibold">{allwhat?.length + allamaz?.length + allwebs?.length}</p>
                                    <p className="stat-txt mb-0 lh-sm body-font-bold text-muted">LEADS</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-md-3 col-6 p-2">
                    <Link to="orders" className="nav-link">
                        <div className="bg-brand-light rounded-brand py-3 px-3">
                            <div className="d-flex align-items-center">
                                <p className="fs-1 mb-0 lh-sm me-3"><i className="fas fa-clipboard-check"></i></p>
                                <div>
                                    <p className="mb-0 lh-sm fs-4 body-font-semibold">{allorders?.length}</p>
                                    <p className="stat-txt mb-0 lh-sm body-font-bold text-muted">ORDERS</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-md-6 col-12 p-2">
                    <div className="nav-link">
                        <div className="bg-brand-light rounded-brand py-3 px-3">
                            <div className="d-flex align-items-center">
                                <p className="fs-1 mb-0 lh-sm me-3"><i className="fas fa-rupee-sign"></i></p>
                                <div>
                                    <p className="mb-0 lh-sm fs-4 body-font-semibold">{totalsale}</p>
                                    <p className="stat-txt mb-0 lh-sm body-font-bold text-muted">SALES</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="top-pros mt-5">
                <h2 className="fs-5 body-font-bold pb-3">Top Ten Products</h2>
                <div className="row">   
                    {
                        mostlovedpro?.map((pro, ind) => (
                            <div key={ind} className="col-md-6 mb-4">
                                <Tablecard name={pro?.name} id={pro?.proid} price={pro?.price} disprice={pro?.discountprice} img={splitFunc(pro?.colornimage[0].url)[0]} quantity={mostobj[pro.proid]}/>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="top-pros mt-5">
                <h2 className="fs-5 body-font-bold pb-3">Top Ten Most Viewed</h2>
                <div className="row">   
                    {
                        mostviewed?.map((pro, ind) => (
                            <div key={ind} className="col-md-6 mb-4">
                                <Tablecard name={pro?.name} id={pro?.proid} price={pro?.price} disprice={pro?.discountprice} img={splitFunc(pro?.colornimage[0].url)[0]} quantity={pro.views.length}/>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default Dashstats;