import React, { useEffect, useState } from 'react';
import './Detail.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Productcard from '../../Components/Productcard/Productcard';
import { useAuth } from '../../Context/Authcontext';

function Detail() {

    const { id } = useParams();
    const navigate = useNavigate();
    const {userdata, isloggedin, login} = useAuth();
    const [qunatity, setQuantity] = useState(1);
    const [prodata, setProdata] = useState({});
    const [colorind, setColorind] = useState(0);
    const [imgurl, setImgurl] = useState([]);
    const [allpro, setAllpro] = useState([]);
    const [relatedpros, setRelatedpros] = useState([]);
    const [isreadmore, setIsreadmore] = useState(false);
    const [logpop, setLogpop] = useState(false);
    const [canpop, setCanpop] = useState(false);
    const [confirmpop, setConfirmpop] = useState(false);
    const [loaderdis, setLoaderdis] = useState('none');
    const [popind, setPopind] = useState(0);
    const [mobno, setMobno] = useState('');

    useEffect(()=> window.scrollTo(0, 0), []);
    
    useEffect(()=> window.scrollTo(0, 0), [id]);

    useEffect(() => {
        if(userdata){
            fetch(`https://homejoy.onrender.com/countview/${id}/${userdata?._id}`).then(res => res.json()).then(data => console.log(data)).catch(err => console.log('error occured while counting the view ', err));
        }
    }, [userdata, id])

    useEffect(()=> {
        if(isloggedin){
            setPopind(1);
        }else{
            setPopind(0);
        }
    }, [isloggedin]);

    useEffect(()=> {
        if(isloggedin){
            setMobno(userdata?.number);
            setPopind(1);
        }else{
            if(localStorage.getItem('usernum')){
                setMobno(JSON.parse(localStorage.getItem('usernum')));
                setPopind(1);
            }
        }
    }, [isloggedin, userdata])

    useEffect(()=> {
        fetch(`https://homejoy.onrender.com/pro/${id}`).then(res => res.json()).then(data => {
            if(data.success){
                setProdata(data.pro);
                console.log(data);
            }else{
                console.log(data);
            }
        }).catch(err => console.log('error occured while getting the single data ', err));
    }, [id]);

    useEffect(()=> {
        fetch(`https://homejoy.onrender.com`).then(res => res.json()).then(data => {
            if(data.success){
                console.log(data);
                setAllpro(data.pro);
            }else{
                console.log(data);
            }
        }).catch(err => console.log('error occured while getting all the product ', err));
    }, []);

    useEffect(()=> {
        if(allpro?.length > 0){
            const samecat = allpro?.filter(e => e.category.toLowerCase() === prodata?.category?.toLowerCase());
            const rmsame = samecat.filter(e => e.proid !== id);
            console.log(rmsame);
            setRelatedpros(rmsame);
        }
    }, [allpro, prodata, id])

    useEffect(()=> {
        if (prodata?.colornimage && prodata.colornimage[colorind]?.url) {
            setImgurl(prodata.colornimage[colorind].url.split(','));
        }    
    }, [prodata, colorind, setImgurl]);

    const estimateTimeFunc = () => {
        const currentDate = new Date();
        const newDate = currentDate;
        newDate.setDate(newDate.getDate() + 3);
        const estimateDate = `${newDate.getDate()} ${newDate.toLocaleDateString('en-US', {month: 'long'})}`;
        newDate.setDate(newDate.getDate() + 6);
        const lastestimateDate = `${newDate.getDate()} ${newDate.toLocaleDateString('en-US', {month: 'long'})}`;
        return {start: estimateDate, end: lastestimateDate};
    };

    const herocaro = {
        customPaging: function (i) {
            return (
                <div className='dot-img p-0'>
                    <img src={imgurl[i]} alt="" className="img-fluid" />
                </div>
            )
        },
        dots: true,
        infinite: true,
        autoplay: true, 
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
    }

    const firstOrderFunc = () => {
        if(isloggedin){
            // setConfirmpop(true);
            setLogpop(true); 
        }else{
            setLogpop(true); 
        };
    };

    const orderNowFunc = () => {
        if(isloggedin){
            setLoaderdis("flex");
            fetch(`https://homejoy.onrender.com/order/${userdata?._id}/${id}`).then(res => res.json()).then(data => {
                console.log(data);
                if(data.success){
                    console.log('success');
                    login(data.pro);
                    navigate('/history')
                }else{
                    console.log('unsuccess');
                }
                setLoaderdis('none');
            }).catch(err => console.log('error occured while booking a product ', err));
        }else{
            setLogpop(true);
        }
    }

    // const addtoCartFunc = () => {
    //     if(isloggedin){
    //         setLoaderdis('flex');
    //         fetch(`https://homejoy.onrender.com/addtocart/${userdata?._id}/${id}`).then(res => res.json()).then(data => {
    //             console.log(data);
    //             if(data.success){
    //                 console.log('success');
    //                 login(data.pro);
    //             }else{
    //                 console.log('unsuccess');
    //             }
    //             setLoaderdis('none');
    //         }).catch(err => console.log('error occured while adding the product to the cart ', err));
    //     }else{
    //         setLogpop(true);
    //     }
    // }

    const nextFunc = () => {
        if(mobno.length === 10){
            localStorage.setItem("usernum", JSON.stringify(mobno));
            setPopind(1);
        }
    }

    const forOrderFunc = (pla) => {
        setLoaderdis('flex')
        fetch(`https://homejoy.onrender.com/order`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user: mobno,
                productid: prodata.proid,
                platform: pla === 0? 'amazon': 'whatsapp',
                quantity: qunatity
            })
        }).then(res => res.json()).then(data => {
            console.log(data);
            if(data.success){
                if(pla === 0){
                    window.open(prodata.buylink, '_blank');
                }else{
                    window.open('https://wa.me/+910987654321', '_blank');
                };
                setLoaderdis('none');
                setLogpop(false);
            }
        }).catch(err => console.log('error occured while booking fororder ', err));
    };

    // const ourSiteFunc = () => {
    //     if(!isloggedin){
    //         localStorage.setItem('cartpro', id);
    //         navigate('/cart');
    //     }
    // }

    const splitFunc = (str)=> {
        const imgFst = str.split(',');
        console.log(imgFst)
        return imgFst;
    }

    const siteOrderFunc = () => {
        setLoaderdis('flex')
        if(isloggedin){
            fetch(`https://homejoy.onrender.com/addtocart/${userdata._id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({id: id, quantity: qunatity, proname: prodata?.name})
            }).then(res => res.json()).then(data => {
                if(data.success){
                    setLoaderdis('none');
                    navigate('/cart');
                    login(data.pro);
                }else{
                    console.log(data);
                    setLoaderdis('none');
                }
            }).catch(err => console.log('error occured while ordering the product form our site ', err));
        }else{
            const loaclCart = localStorage.getItem("cart");
            if(!loaclCart){
                const cartarr = [{id: id, quantity: qunatity, proname: prodata?.name}];
                localStorage.setItem('cart', JSON.stringify(cartarr));
                navigate('/cart');
                setLoaderdis('none');
            }else{
                const updatedcart = JSON.parse(loaclCart);
                let found = false;
                updatedcart.forEach(arr => {
                    if(arr.id === id){
                        arr.quantity = qunatity;
                        localStorage.setItem('cart', JSON.stringify(updatedcart));
                        navigate('/cart');
                        setLoaderdis('none');
                        found = true;
                    }
                });
                if(!found){
                    updatedcart.push({id: id, quantity: qunatity, proname: prodata?.name});
                    localStorage.setItem('cart', JSON.stringify(updatedcart));
                    navigate('/cart');
                    setLoaderdis('none');
                }
            }
        }
    }

  return (
    <div className='main-detail'>
        <div className="container-fluid mx-auto mt-4">
            <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-4 p-3 pt-0 mb-4 detail-img-wrap">
                    <Slider {...herocaro}>
                        {
                                imgurl?.map((url, ind) => (
                                    url !== '' && (
                                        <div key={ind} className="detail-img rounded-brand overflow-hidden bg-brand-light">
                                            <img src={url} alt="girl img" className="img-fluid w-100" />
                                        </div>
                                    )
                                ))
                            
                        }
                    </Slider>
                </div>
                <div className="col-md-7">
                    <div className="name-wrap d-flex justify-content-between align-items-start">
                        <div>
                            <p className="mb-0 body-font-bold text-muted">{prodata?.category}</p>
                            <p className="mb-0 fs-4 body-font-bold">{prodata?.name}</p>
                        </div>
                        <div className="heart-wrap btn btn-outline-dark p-3 border rounded-brand mt-4">
                            <p className="mb-0 p-0 lh-sm"><i className="far fa-heart fs-5"></i></p>
                        </div>
                    </div>
                    <div className="review-wrap mt-3">
                        <p className="mb-0">
                            <i className={`fas fa-star me-1 ${prodata?.stars > 0? 'text-warning': 'text-secondary'}`}></i>
                            <i className={`fas fa-star me-1 ${prodata?.stars > 1? 'text-warning': 'text-secondary'}`}></i>
                            <i className={`fas fa-star me-1 ${prodata?.stars > 2? 'text-warning': 'text-secondary'}`}></i>
                            <i className={`fas fa-star me-1 ${prodata?.stars > 3? 'text-warning': 'text-secondary'}`}></i>
                            <i className={`fas fa-star me-1 ${prodata?.stars > 4.5? 'text-warning': 'text-secondary'}`}></i> <span className="text-muted">({prodata?.noreviews} reviews)</span>
                        </p>
                    </div>
                    <div className="price-wrap mt-3">
                        <p className="fs-4 mb-0 body-font-bold">₹{prodata?.discountprice} <span className="text-muted fs-6 text-decoration-line-through ms-3 ps-3 border-start">₹{prodata?.price}</span><span className="ms-3 bg-brand-green rounded-pill px-3 fs-6">- {100 - Math.round(prodata?.discountprice/prodata?.price*100)}%</span></p>
                        <p className="mb-0 body-font-regular text-muted fs-sm mt-2">Included Of All Taxes</p>
                    </div>
                    <div className="mt-3 pt-4 border-top">
                        <h2 className="fs-5 body-font-bold">Colors</h2>
                        <div className="d-flex flex-wrap">
                            {
                                prodata?.colornimage?.map((col, ind) => (
                                    <div key={ind} onClick={()=> setColorind(ind)} className="product-color me-2" style={{backgroundColor: col.color}}></div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="add-to-cart mt-4">
                        <div className="row align-items-center px-2">
                            <div className="col-md-3 col-6 d-flex justify-content-between align-items-center border rounded-brand-sm overflow-hidden">
                                <button className='btn bg-white fs-5' onClick={()=> setQuantity(qunatity > 1? qunatity - 1: 1)}>-</button>
                                <p className="mb-0 fs-5 px-3">{qunatity}</p>
                                <button className='btn bg-white fs-5' onClick={()=> setQuantity(qunatity < 10? qunatity + 1: 10)}>+</button>
                            </div>
                            <div className="col-6 pe-0 mob-order-btn-sm">
                                {
                                    userdata?.order?.includes(id)? (
                                        <button className="btn btn-outline-dark w-100 rounded-brand-sm fs-5" onClick={()=> setCanpop(true)}><p className="nav-link mb-0">CANCEL ORDER</p></button>
                                    ):(
                                        <button className="btn btn-dark w-100 rounded-brand-sm fs-5" onClick={firstOrderFunc}><p className="nav-link mb-0">ORDER NOW</p></button>
                                    )
                                }
                            </div>
                            {/* <div className="col-md-8 col-6 pe-0">
                                {
                                    userdata?.cart?.includes(id)? (
                                        <button className="add-to-cart-btn btn bg-dark text-white border border-dark w-100 fs-5 rounded-brand-sm" onClick={addtoCartFunc}>Already in cart</button>
                                    ):(
                                        <button className="add-to-cart-btn btn bg-white border border-dark w-100 fs-5 rounded-brand-sm" onClick={addtoCartFunc}>Add to cart</button>
                                    )
                                }
                            </div> */}
                            <div className="col-md-8 desk-buy-btn">
                                {
                                    userdata?.order?.includes(id)? (
                                        <button className="btn btn-outline-dark w-100 rounded-brand-sm fs-4" onClick={()=> setCanpop(true)}><p className="nav-link mb-0">CANCEL ORDER</p></button>
                                    ):(
                                        <button className="btn btn-dark w-100 rounded-brand-sm fs-4" onClick={firstOrderFunc}><p className="nav-link mb-0">ORDER NOW</p></button>
                                    )
                                }
                            </div>
                            <div className="mob-buy-btn row m-0 py-2 px-0 bg-white border-0">
                                <div className="col-2 pe-0">
                                    <button className="hovering-heart border border-dark bg-white rounded-brand-sm me-2"><i className="far fa-heart fs-3"></i></button>
                                </div>
                                <div className="col-10">
                                    {
                                        userdata?.order?.includes(id)? (
                                            <button className="btn btn-outline-dark bg-white w-100 rounded-brand-sm fs-4" onClick={() => setCanpop(true)}><p className="nav-link mb-0">CANCEL ORDER</p></button>
                                        ):(
                                            <button className="btn btn-dark w-100 rounded-brand-sm fs-4" onClick={firstOrderFunc}><p className="nav-link mb-0">ORDER NOW</p></button>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="accordion mt-5 border rounded-brand overflow-hidden" id="accordionExample">
                        <div className="accordion-item border-0 border-bottom">
                            <h2 className="accordion-header">
                            <button className="accordion-button shadow-none border-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseZero" aria-expanded="true" aria-controls="collapseZero">
                                Delivery Estimates
                            </button>
                            </h2>
                            <div id="collapseZero" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="extra-info-wrap">
                                        <p className="mb-2 body-font-semibold"><i className="far fa-clock me-2"> </i> Estimate Time: <span className="text-muted ms-2">{`${estimateTimeFunc().start} - ${estimateTimeFunc().end}`}</span></p>
                                        <p className="mb-2 body-font-semibold"><i className="fas fa-th-large me-2"> </i> Category: <span className="text-muted ms-2">{prodata?.category}</span></p>
                                        <p className="mb-2 body-font-semibold"><i className="fas fa-tag me-2"> </i> 
                                            Tag: <span className="text-muted ms-2 text-capitalize">{
                                                prodata?.tag?.map((tag, ind) => (
                                                    `${tag}${ind === prodata?.tag?.length -1? '': ','} `
                                                ))
                                            }</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item border-0 border-bottom">
                            <h2 className="accordion-header">
                            <button className="accordion-button shadow-none border-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Product Description
                            </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className="description-wrap">
                                    <p className="mb-0 text-muted body-font-medium">
                                        {prodata?.description?.length > 300 && !isreadmore? prodata?.description?.slice(0, 300) + '...': prodata?.description }
                                        {
                                            prodata?.description?.length > 300 && (
                                                <span className="read-more text-primary cursor-pointer" style={{display: isreadmore && 'none'}} onClick={()=> setIsreadmore(true)}>Read more</span>
                                            )
                                        }
                                        {
                                            prodata?.description?.length > 300 && (
                                                <span className="read-less text-primary cursor-pointer" style={{display: isreadmore? 'inline': 'none'}} onClick={()=> setIsreadmore(false)}>Read less</span>
                                            )
                                        }
                                    </p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item border-0 border-bottom">
                            <h2 className="accordion-header">
                            <button className="accordion-button shadow-none border-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Product Details
                            </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="mt-0 pt-0">
                                        {
                                            prodata?.features?.map((feat, ind) => (
                                                <div key={ind} className="row px-2">
                                                    <div className="col-5 p-2 text-capitalize body-font-semibold">{feat.name}</div>
                                                    <div className="col-7 p-2 text-capitalize text-muted">{feat.description}</div>
                                                </div> 
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item border-0">
                            <h2 className="accordion-header">
                            <button className="accordion-button shadow-none border-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Perks
                            </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="row align-items-center">
                                        {/* <div className="col-md-1 col-3 mb-3">
                                            <img src="/assets/shipping-icon.png" alt="" className="img-fluid w-100" />
                                        </div> */}
                                        <div className="col-md-11 col-9 mb-3">
                                            <p className="mb-0 body-font-semibold">Free Shipping</p>
                                            <p className="mb-0 text-muted">Free shpping on order over ₹2000.</p>
                                        </div>
                                        {/* <div className="col-md-1 col-3 mb-3">
                                            <img src="/assets/24-7-icon.png" alt="" className="img-fluid w-100" />
                                        </div> */}
                                        <div className="col-md-11 col-9 mb-3">
                                            <p className="mb-0 body-font-semibold">24 / 7 Support</p>
                                            <p className="mb-0 text-muted">24/7 customer support from our team.</p>
                                        </div>
                                        {/* <div className="col-md-1 col-3 mb-3">
                                            <img src="/assets/guarantee-icon.png" alt="" className="img-fluid w-100" />
                                        </div> */}
                                        <div className="col-md-11 col-9">
                                            <p className="mb-0 body-font-semibold">Guarantee Return</p>
                                            <p className="mb-0 text-muted">Not impressed? Get a refund.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row p-0 m-0">
                    <div className="col-md-3">
                        
                    </div>
                </div>
            </div>
            <div className="suggetions-wrap mt-5 pt-5 border-top">
                <h2 className="fs-5 body-font-bold">Related Products</h2>
                <div className="mt-4 row p-0 m-0">
                    {/* <Slider {...relatedcaro}> */}
                        {
                            relatedpros?.map((pro, ind) => (
                                <div key={ind} className="col-md-3 col-6 mb-5 pe-2">
                                    <Productcard coverimg={splitFunc(pro.colornimage[0].url)[0]} hoverimg={splitFunc(pro.colornimage[0].url)[1]} tag={pro.status} saletxt={pro.spinningtxt} name={pro.name} price={pro.discountprice} preprice={pro.price} id={pro.proid} />
                                </div>
                            ))
                        }
                    {/* </Slider> */}
                </div>
            </div>
        </div>
        <div className="full-overlay logpop-wrapper" style={{display: logpop? 'flex': 'none'}}>
            <div className="logpop-wrap d-flex justify-content-end">
                <p className="mb-0 w-max-content p-1 pe-3 text-white fs-4 cursor-pointer" onClick={()=> setLogpop(false)}><i className="fas fa-times"></i></p>
            </div>
            {
                popind === 0 && (
                    <div className="logpop-wrap bg-white rounded-brand-lg overflow-hidden p-3 py-4">
                        <h2 className="fs-2 mb-1 text-center">Get <span className="body-font-extrabold">Started</span></h2>
                        <p className="mb-4 text-muted text-center fs-sm">Provide you phone number to move forward.</p>
                        <div className="d-flex align-items-center p-0 border border-dark rounded-brand overflow-hidden py-1">
                            <p className="mb-0 body-font-medium px-2 border-end text-nowrap">+ 91</p>
                            <input type="text" name="number" id="number" className=" m-0 form-control shadow-none outline-none border-0 rounded-brand-sm" placeholder='Your number' value={mobno} onChange={(e)=> setMobno(e.target.value)}/>
                        </div>
                        <button className='btn btn-dark fs-5 rounded-brand w-100 py-2 letter-spacing mt-4' onClick={nextFunc}>NEXT <i className="fas fa-long-arrow-alt-right"></i></button>
                        <p className="fs-sm text-muted text-center mt-4">
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Blanditiis, dolor nostrum qui harum numquam sed. Repellat repudiandae eveniet quis itaque, necessitatibus in similique <Link to="/terms-and-conditions" className='nav-link. text-primary'>Terms & Conditions</Link> and <Link to="/privacy-policy" className='nav-link. text-primary'>Privacy Policy</Link>.
                        </p>
                    </div>
                )
            }
            {
                popind === 1 && (
                    <div className="logpop-wrap bg-white rounded-brand-lg overflow-hidden p-3 py-4">
                        <h2 className="fs-2 mb-4 text-center">Buy it on</h2>
                        {/* <p className="mb-3 cursor-pointer letter-spacing body-font-light py-3 text-center text-white bg-dark rounded-brand fs-5 lh-sm" onClick={()=> forOrderFunc(0)}><i className="fab fa-amazon me-2"></i>AMAZON <br /> <span className="fs-sm text-light">50% OFF</span></p>
                        <p className="mb-3 cursor-pointer letter-spacing body-font-light py-3 text-center text-white bg-dark rounded-brand fs-5 lh-sm" onClick={()=> forOrderFunc(1)}><i className="fab fa-whatsapp me-2"></i>WHATSAPP <br /> <span className='fs-sm text-light'>30% OFF</span></p>
                        <Link to="/cart" className="nav-link mb-2 letter-spacing body-font-light py-3 text-center text-dark bg-brand-light rounded-brand fs-5"><i className="fas fa-globe me-2"></i>OUR WEBSITE</Link> */}
                        <div className="d-flex align-items-center justify-content-between bg-brand-light border border-dark rounded-pill p-3 mb-3 cursor-pointer" onClick={()=> forOrderFunc(0)}>
                            <div className="d-flex align-items-center">
                                <div className="icon-wrap me-3">
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/4/4a/Amazon_icon.svg" alt="amazon logo" width='40px' className="img-fluid" />
                                </div>
                                <div className="btn-text-wrap">
                                    <p className="mb-0 fs-5 body-font-extrabold">Super Fast Delivery</p>
                                    <p className="mb-0 fs-sm letter-spacing text-muted">AMAZON</p>
                                </div>
                            </div>
                            <div className="arrow-wrap">
                                <p className="mb-0 arrow-para">
                                    <i className="fas fa-arrow-right text-white fs-4"></i>
                                </p>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between bg-brand-light border border-dark rounded-pill p-3 mb-3 cursor-pointer" onClick={()=> forOrderFunc(1)}>
                            <div className="d-flex align-items-center">
                                <div className="icon-wrap me-3">
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png" alt="whatsapp logo" width='40px' className="img-fluid" />
                                </div>
                                <div className="btn-text-wrap">
                                    <p className="mb-0 fs-5 body-font-extrabold">₹150% OFF</p>
                                    <p className="mb-0 fs-sm letter-spacing text-muted">WHATSAPP</p>
                                </div>
                            </div>
                            <div className="arrow-wrap">
                                <p className="mb-0 arrow-para">
                                    <i className="fas fa-arrow-right text-white fs-4"></i>
                                </p>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between bg-brand-light border border-dark rounded-pill p-3 mb-3 cursor-pointer" onClick={siteOrderFunc}>
                            <div className="d-flex align-items-center">
                                <div className="icon-wrap me-3">
                                    <img src="/assets/homejoylogo.png" alt="amazon logo" width='40px' className="img-fluid" />
                                </div>
                                <div className="btn-text-wrap">
                                    <p className="mb-0 fs-5 body-font-extrabold">COD Available</p>
                                    <p className="mb-0 fs-sm letter-spacing text-muted">OUR WEBSITE</p>
                                </div>
                            </div>
                            <div className="arrow-wrap">
                                <p className="mb-0 arrow-para">
                                    <i className="fas fa-arrow-right text-white fs-4"></i>
                                </p>
                            </div>
                        </div>
                    </div>
                )
            }
            {/* <div className="logpop-wrap bg-white rounded-brand-lg overflow-hidden p-3 py-4">
                <h2 className="fs-2 mb-1 text-center"><span className="body-font-extrabold">Verify</span> Your Number</h2>
                <p className="mb-4 text-muted text-center fs-sm">We've sent OTP on your number.</p>
                <p className="mb-2 body-font-bold fs-5 text-center">Your OTP</p>
                <input type="text" name="number" id="number" className="form-control shadow-none outline-none border border-dark rounded-brand-sm" placeholder='Your number'/>
                <button className='btn btn-dark fs-5 rounded-brand w-100 py-2 letter-spacing mt-4'>NEXT <i className="fas fa-long-arrow-alt-right ms-2"></i></button>
                <p className="fs-sm text-muted text-center mt-4">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Blanditiis, dolor nostrum qui harum numquam sed. Repellat repudiandae eveniet quis itaque, necessitatibus in similique <Link to="/terms-and-conditions" className='nav-link. text-primary'>Terms & Conditions</Link> and <Link to="/privacy-policy" className='nav-link. text-primary'>Privacy Policy</Link>.
                </p>
            </div> */}
        </div>
        <div className="full-overlay" style={{display: canpop? 'flex': 'none'}}>
            <div className="logpop-wrap bg-white rounded-brand-lg overflow-hidden p-3 py-4">
                <h2 className="fs-4 body-font-bold mb-4 text-center">Do you want to cancele this order ?</h2>
                <div className="d-flex align-items-center">
                    <button className='btn btn-dark fs-5 rounded-brand w-100 py-2 letter-spacing me-1' onClick={()=> {orderNowFunc(); setCanpop(false)}}>YES</button>
                    <button className='btn btn-outline-dark fs-5 rounded-brand w-100 py-2 letter-spacing ms-1' onClick={()=> setCanpop(false)}>NO</button>
                </div>
            </div>
        </div>
        {
            isloggedin && (
                <div className="full-overlay confirmation-wrap" style={{display: confirmpop? 'flex': 'none'}}>
                    <div className="d-flex justify-content-end confirmation-pop-cross">
                        <div className="w-max-content">
                            <p className="mb-0 cross-para cursor-pointer mb-2" onClick={()=> setConfirmpop(false)}><i className="fas fa-times"></i></p>
                        </div>
                    </div>
                    <div className="confirmation-pop bg-white rounded-brand-lg rounded-bottom-0 overflow-hidden p-3 py-4">
                        <h2 className="fs-4 body-font-bold mb-4 text-center">Order Confirmation</h2>
                        <div className="order-detail bg-white rounded-brand border">
                            <div className="py-2 border-bottom p-2">
                                <p className="mb-0 text-muted body-font-light">Shipping to <span className="body-font-bold text-capitalize">{userdata?.name}</span></p>
                            </div>
                            <div className="py-2 border-bottom p-2 d-flex justify-content-between align-items-center">
                                <p className="text-muted mb-0">Items:</p>
                                <p className="mb-0 text-capitalize body-font-bold text-end">₹{prodata.discountprice}</p>
                            </div>
                            <div className="py-2 border-bottom p-2 d-flex justify-content-between align-items-center">
                                <p className="text-muted mb-0">Delivery:</p>
                                <p className="mb-0 text-capitalize body-font-bold text-end">₹40</p>
                            </div>
                            <div className="py-2 p-2 d-flex justify-content-between align-items-center">
                                <p className="mb-0 fs-5 body-font-bold">Total Order:</p>
                                <p className="mb-0 text-capitalize body-font-bold text-end fs-5 text-success">₹{prodata.discountprice + 40}</p>
                            </div>
                        </div>
                        <div className="user-detail bg-white rounded-brand border mt-4">
                            <div className="py-2 border-bottom p-2">
                                <p className="mb-0">Deliver to</p>
                            </div>
                            <div className="py-2 border-bottom p-2 d-flex justify-content-between align-items-center">
                                <p className="text-muted mb-0">Name:</p>
                                <p className="mb-0 text-capitalize body-font-bold text-end">{userdata?.name}</p>
                            </div>
                            <div className="py-2 border-bottom p-2 d-flex justify-content-between">
                                <p className="text-muted mb-0">Address:</p>
                                <p className="mb-0 text-capitalize body-font-bold text-end">{userdata?.address}</p>
                            </div>
                            <div className="py-2 border-bottom p-2 d-flex justify-content-between align-items-center">
                                <p className="text-muted mb-0">Number:</p>
                                <p className="mb-0 text-capitalize body-font-bold text-end">{userdata?.number}</p>
                            </div>
                            <Link to="/editprofile" className="nav-link p-0 m-0">
                                <div className="py-2 p-2 d-flex justify-content-between align-items-center cursor-pointer">
                                    <p className="mb-0">Change Details</p>
                                    <p className="mb-0 text-capitalize body-font-bold text-end"><i className="fas fa-chevron-right ms-3"></i></p>
                                </div>
                            </Link>
                        </div>
                        <div className="user-detail bg-white rounded-brand border mt-4">
                            <div className="py-2 border-bottom p-2">
                                <p className="mb-0">Product</p>
                            </div>
                            <div className="py-2 border-bottom p-2">
                                <div className="row p-0 m-0 align-items-center">
                                    <div className="col-4">
                                        <img src={imgurl[0]} alt="product thumbnail" className="img-fluid" />
                                    </div>
                                    <div className="col-8">
                                        <p className="body-font-bold mb-0 text-capitalize">{prodata.name}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="py-2 border-bottom p-2 d-flex justify-content-between align-items-center">
                                <p className="text-muted mb-0">Quantity:</p>
                                <p className="mb-0 text-capitalize body-font-bold text-end">1</p>
                            </div>
                            <div className="py-2 p-2 d-flex justify-content-between align-items-center">
                                <p className="text-muted mb-0">Payment Method:</p>
                                <p className="mb-0 text-capitalize body-font-bold text-end">Cash on delivery</p>
                            </div>
                        </div>
                        <div className="p-2">
                            <p className="mb-0 mt-4 text-muted fs-sm">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis enim odio, doloremque, quos ex amet aperiam sapiente cupiditate eligendi repellendus aut eaque incidunt accusantium. Placeat, inventore nam. Quidem, accusamus sequi.
                            </p>
                        </div>
                        {
                            userdata?.order?.includes(id)? (
                                <button className="btn btn-outline-dark rounded-pill w-100 letter-spacing mt-4 fs-5 py-2" onClick={()=> setCanpop(true)}>CANCLE ORDER</button>
                            ): (
                                <button className="btn btn-dark rounded-pill w-100 letter-spacing mt-4 fs-5 py-2" onClick={firstOrderFunc}>PLACE ORDER NOW</button>
                            )
                        }
                    </div>
                </div>
            )
        }
        <div className="full-overlay" style={{display: loaderdis}}>
            <div className="loader-wrap rounded-brand p-4">
                <div className="loader-roundedr"></div>
            </div>
        </div>
    </div>
  )
}

export default Detail