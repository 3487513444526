import React, { useEffect, useState } from 'react';
import './Home.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Productcard from '../../Components/Productcard/Productcard';
import Collectioncard from '../../Components/Collectioncard/Collectioncard';
import { Link } from 'react-router-dom';

// const CustomPrevArrow = (props) => {
//     const { className, style, onClick } = props;
//     return (
//         <div
//             className={`${className} custom-arrow custom-prev-arrow`}
//             style={{ ...style, display: "block" }}
//             onClick={onClick}
//         />
//     );
// };

// const CustomNextArrow = (props) => {
//     const { className, style, onClick } = props;
//     return (
//         <div
//             className={`${className} custom-arrow custom-next-arrow`}
//             style={{ ...style, display: "block" }}
//             onClick={onClick}
//         />
//     );
// };

function Home() {

    const [cattabs, setCattabs] = useState(1);
    const [sellertab, setSellertab] = useState(1);
    const [prodata, setProdata] = useState([]);

    useEffect(()=> window.scrollTo(0, 0), []);
    
    const herocaro = {
        infinite: true,
        dots: true,
        arrows: false,
        slidesToShow: 1,
        autoplay: true,
        slidesToScroll: 1,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
    }
    const mobherocaro = {
        infinite: true,
        dots: true,
        arrows: false,
        slidesToShow: 1,
        autoplay: true,
        slidesToScroll: 1,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
    }
    // const collectioncaro = {
    //     infinite: true,
    //     dots: false,
    //     slidesToShow: 6,
    //     autoplay: true,
    //     slidesToScroll: 1,
    //     pauseOnHover: false,
    //     arrows: true,
    //     nextArrow: <CustomNextArrow />,
    //     prevArrow: <CustomPrevArrow />,
    //     responsive: [
    //         {
    //             breakpoint: 1000,
    //             settings: {
    //                 slidesToShow: 6,
    //                 slidesToScroll: 1,
    //                 dots: false,
    //             },
    //         },
    //         {
    //             breakpoint: 600,
    //             settings: {
    //                 slidesToShow: 3,
    //                 slidesToScroll: 1,
    //                 dots: false,
    //             },
    //         },
    //     ],
    // };

    const reviewcaro = {
        infinite: true,
        dots: true,
        arrows: false,
        // slidesToShow: 1,
        autoplay: true,
        slidesToScroll: 1,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
    };

    useEffect(()=> {
        fetch(`https://homejoy.onrender.com`).then(res => res.json()).then(data => {
            if(data.success){
                console.log(data);
                console.log(data.pro[0].colornimage)
                setProdata(data.pro);
            }else{
                console.log(data);
            }
        }).catch(err => console.log('error occured while getting all the pros ', err));
    }, []);

    useEffect(()=> console.log(prodata), [prodata]);

    const splitFunc = (str)=> {
        const imgFst = str.split(',');
        console.log(imgFst)
        return imgFst;
    }

    return (
        <div className='main-home overflow-hidden'>
            <div className="container-fluid desk-hero mx-auto bg-brand-light m-0 p-0">
                <Slider {...herocaro}>
                    <div className="slider-wrap">
                        <div className="main-hero-div row align-items-center container mx-auto p-0 m-0">
                            <div className="col-md-6">
                                <p className="mb-0 text-dark text-muted body-font-semibold">SALE! UP TO 50% OFF!</p>
                                <p className="mb-0 hero-text text-dark body-font-semibold lh-sm">Home & Decor <br />Accessories</p>
                                <button className="btn btn-dark px-4 py-2 body-font-semibold rounded-3 mt-4">Shop Now</button>
                            </div>
                            <div className="col-md-6">
                                <img src="/assets/hero-accessor1.png" alt="" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                    <div className="slider-wrap">
                        <div className="main-hero-div row align-items-center container mx-auto p-0 m-0">
                            <div className="col-md-6">
                                <p className="mb-0 text-dark text-muted body-font-semibold">SALE! UP TO 50% OFF!</p>
                                <p className="mb-0 hero-text text-dark body-font-semibold lh-sm">Afficient Kitchen <br />Accessories</p>
                                <button className="btn btn-dark px-4 py-2 body-font-semibold rounded-3 mt-4">Shop Now</button>
                            </div>
                            <div className="col-md-6">
                                <img src="/assets/hero-kitchen1.png" alt="" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                    <div className="slider-wrap">
                        <div className="main-hero-div row align-items-center container mx-auto p-0 m-0">
                            <div className="col-md-6">
                                <p className="mb-0 text-dark text-muted body-font-semibold">SALE! UP TO 50% OFF!</p>
                                <p className="mb-0 hero-text text-dark body-font-semibold lh-sm">Best Cleaning <br />Accessories</p>
                                <button className="btn btn-dark px-4 py-2 body-font-semibold rounded-3 mt-4">Shop Now</button>
                            </div>
                            <div className="col-md-6 p-0">
                                <img src="/assets/hero-cleaning1.png" alt="" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
            <div className="container-fluid mob-hero bg-brand-light p-0 pb-2">
                <div className="banner-wrap">
                    <div className="hero-banner">
                        <Slider {...mobherocaro}>
                            <div className="hero-banner-wrapper">
                                <div className="row justify-content-between align-items-center py-0 pt-4 w-100 ps-3">
                                    <div className="hero-banner-text col-6">
                                        <p className="mb-0 text-muted">UPTO 50% OFF</p>
                                        <h2 className="mb-0 fs-4 body-font-bold mt-1 text-nowrap">Home & Decor <br />Accesories</h2>
                                        <button className="btn btn-dark px-3 rounded-brand-sm mt-3">Shop Now</button>
                                    </div>
                                    <div className="col-6">
                                        <img src="/assets/hero-accessor1.png" alt="" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div className="hero-banner-wrapper">
                                <div className="row justify-content-between align-items-center py-0 pt-4 w-100 ps-3">
                                    <div className="hero-banner-text col-6">
                                        <p className="mb-0 text-muted">UPTO 50% OFF</p>
                                        <h2 className="mb-0 fs-4 body-font-bold mt-1 text-nowrap">Kitchen <br />Accessories</h2>
                                        <button className="btn btn-dark px-3 rounded-brand-sm mt-3">Shop Now</button>
                                    </div>
                                    <div className="col-6">
                                        <img src="/assets/hero-kitchen1.png" alt="" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div className="hero-banner-wrapper">
                                <div className="row justify-content-between align-items-center py-0 pt-4 w-100 ps-3">
                                    <div className="hero-banner-text col-6">
                                        <p className="mb-0 text-muted">UPTO 50% OFF</p>
                                        <h2 className="mb-0 fs-4 body-font-bold mt-1 text-nowrap">Best Cleaning<br />Accessories</h2>
                                        <button className="btn btn-dark px-3 rounded-brand-sm mt-3">Shop Now</button>
                                    </div>
                                    <div className="col-6">
                                        <img src="/assets/hero-cleaning1.png" alt="" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
            <div className="conainer-fluid">
                <div className="whats-new-card-wrap mt-5 pb-3">
                    <h2 className="text-center mb-4 fs-2 body-font-bold">Explore Collections</h2>
                    <div className='row p-0 m-0'>
                        <div className="col-md-2 col-4 p-1">
                            <Collectioncard name="Kitchen" img="../../../assets/kitchenimg.jpg" />
                        </div>
                        <div className="col-md-2 col-4 p-1">
                            <Collectioncard name="Kids" img="../../../assets/kidsimg.webp" />
                        </div>
                        <div className="col-md-2 col-4 p-1">
                            <Collectioncard name="Home" img="../../../assets/homeimg.jpg" />
                        </div>
                        <div className="col-md-2 col-4 p-1">
                            <Collectioncard name="Bathroom" img="../../../assets/bathroomimg.jpg" />
                        </div>
                        <div className="col-md-2 col-4 p-1">
                            <Collectioncard name="Storage" img="../../../assets/storageimg.jpg" />
                        </div>
                        <div className="col-md-2 col-4 p-1">
                            <Collectioncard name="Cleaning" img="../../../assets/cleaningimg.webp" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid p-0 pb-5 mt-5 pt-2">
                <div className="banner-wrap desk-banner px-3">
                    <Slider {...herocaro}>
                        <Link to="/product" className='nav-link'>
                            <img src="https://cdn.shopify.com/s/files/1/0550/8890/6475/files/home___decor_2_1.jpg?v=1634120765" alt="" className="img-fluid w-100 rounded-brand" />
                        </Link>
                        <Link to="/product" className='nav-link'>
                            <img src="https://cdn.shopify.com/s/files/1/0550/8890/6475/files/home___decor_2_1.jpg?v=1634120765" alt="" className="img-fluid w-100 rounded-brand" />
                        </Link>
                        <Link to="/product" className='nav-link'>
                            <img src="https://cdn.shopify.com/s/files/1/0550/8890/6475/files/home___decor_2_1.jpg?v=1634120765" alt="" className="img-fluid w-100 rounded-brand" />
                        </Link>
                    </Slider>
                </div>
                <div className="banner-wrap mob-banner">
                    <Slider {...herocaro}>
                        <Link to="/product" className='nav-link'>
                            <img src="https://d39vol41m7feya.cloudfront.net/1655709855-2.jpg" alt="" className="img-fluid w-100 rounded-brand" />
                        </Link>
                        <Link to="/product" className='nav-link'>
                            <img src="https://d39vol41m7feya.cloudfront.net/1655709855-2.jpg" alt="" className="img-fluid w-100 rounded-brand" />
                        </Link>
                        <Link to="/product" className='nav-link'>
                            <img src="https://d39vol41m7feya.cloudfront.net/1655709855-2.jpg" alt="" className="img-fluid w-100 rounded-brand" />
                        </Link>
                    </Slider>
                </div>
            </div>
            <div className="container">
                <div className="whats-new-div mt-5 pt-3">
                    <h2 className="text-center fs-2 body-font-bold">What's New</h2>
                    <div className="cat-tabs-wrap desk-tabs mt-4">
                        <div className="cat-tabs bg-brand-light d-flex align-items-center rounded-brand w-max-content mx-auto p-2">
                            <div className="tab-back bg-white shadow-sm rounded-brand-sm" style={{ left: cattabs === 1 ? 10 : 100 * (cattabs - 1) }}></div>
                            <p className={`mb-0 me-3 px-3 py-1 body-font-semibold rounded-brand-sm cursor-pointer ${cattabs === 1 ? 'bg-white shadow-sm' : 'text-muted'}`} onClick={() => setCattabs(1)}>ALL</p>
                            <p className={`mb-0 me-3 px-3 py-1 body-font-semibold rounded-brand-sm cursor-pointer ${cattabs === 2 ? 'bg-white shadow-sm' : 'text-muted'}`} onClick={() => setCattabs(2)}>HOME</p>
                            <p className={`mb-0 me-3 px-3 py-1 body-font-semibold rounded-brand-sm cursor-pointer ${cattabs === 3 ? 'bg-white shadow-sm' : 'text-muted'}`} onClick={() => setCattabs(3)}>KITCHEN</p>
                            <p className={`mb-0 me-3 px-3 py-1 body-font-semibold rounded-brand-sm cursor-pointer ${cattabs === 4 ? 'bg-white shadow-sm' : 'text-muted'}`} onClick={() => setCattabs(4)}>KIDS</p>
                            <p className={`mb-0 me-0 px-3 py-1 body-font-semibold rounded-brand-sm cursor-pointer ${cattabs === 5 ? 'bg-white shadow-sm' : 'text-muted'}`} onClick={() => setCattabs(5)}>BATHROOM</p>
                        </div>
                    </div>
                    <div className="cat-tabs-wrap mob-tabs mt-4">
                        <div className="cat-tabs bg-brand-light d-flex align-items-center rounded-brand w-max-content mx-auto p-2">
                            <div className="tab-back bg-white shadow-sm rounded-brand-sm" style={{ left: cattabs === 1 ? 10 : 100 * (cattabs - 1) }}></div>
                            <p className={`mb-0 me-3 px-3 py-1 body-font-semibold rounded-brand-sm cursor-pointer ${cattabs === 1 ? 'bg-white shadow-sm' : 'text-muted'}`} onClick={() => setCattabs(1)}>ALL</p>
                            <p className={`mb-0 me-3 px-3 py-1 body-font-semibold rounded-brand-sm cursor-pointer ${cattabs === 2 ? 'bg-white shadow-sm' : 'text-muted'}`} onClick={() => setCattabs(2)}>HOME</p>
                            <p className={`mb-0 me-0 px-3 py-1 body-font-semibold rounded-brand-sm cursor-pointer ${cattabs === 3 ? 'bg-white shadow-sm' : 'text-muted'}`} onClick={() => setCattabs(3)}>KIDS</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="whats-new-card-wrap mt-3 pb-5">
                    <div className="new-cards row">
                        {
                            prodata.length > 0 && (
                                prodata.slice(0, 4).map((pro, ind) => (
                                    <div key={ind} className="col-md-3 col-6 mb-4">
                                        <Productcard coverimg={splitFunc(pro.colornimage[0].url)[0]} hoverimg={splitFunc(pro.colornimage[0].url)[1]} tag={pro.status} saletxt={pro.spinningtxt} name={pro.name} price={pro.discountprice} preprice={pro.price} id={pro.proid} />
                                    </div>
                                ))
                            )
                        }
                    </div>
                    <div className="mx-auto w-max-content mt-3 cursor-pointer">
                        <Link to="/product" className="btn btn-brand-light bg-brand-light mx-auto body-font-semibold px-3 rounded-brand-sm">VIEW ALL</Link>
                    </div>
                </div>
            </div>
            <div className="container-fluid p-0 pb-5">
                <div className="banner-wrap desk-banner px-3">
                    <Slider {...herocaro}>
                        <Link to="/product" className='nav-link'>
                            <img src="https://cdn.shopify.com/s/files/1/0550/8890/6475/files/home___decor_2_1.jpg?v=1634120765" alt="" className="img-fluid w-100 rounded-brand" />
                        </Link>
                        <Link to="/product" className='nav-link'>
                            <img src="https://cdn.shopify.com/s/files/1/0550/8890/6475/files/home___decor_2_1.jpg?v=1634120765" alt="" className="img-fluid w-100 rounded-brand" />
                        </Link>
                        <Link to="/product" className='nav-link'>
                            <img src="https://cdn.shopify.com/s/files/1/0550/8890/6475/files/home___decor_2_1.jpg?v=1634120765" alt="" className="img-fluid w-100 rounded-brand" />
                        </Link>
                    </Slider>
                </div>
                <div className="banner-wrap mob-banner">
                    <Slider {...herocaro}>
                        <Link to="/product" className='nav-link'>
                            <img src="https://d39vol41m7feya.cloudfront.net/1655709855-2.jpg" alt="" className="img-fluid w-100 rounded-brand" />
                        </Link>
                        <Link to="/product" className='nav-link'>
                            <img src="https://d39vol41m7feya.cloudfront.net/1655709855-2.jpg" alt="" className="img-fluid w-100 rounded-brand" />
                        </Link>
                        <Link to="/product" className='nav-link'>
                            <img src="https://d39vol41m7feya.cloudfront.net/1655709855-2.jpg" alt="" className="img-fluid w-100 rounded-brand" />
                        </Link>
                    </Slider>
                </div>
            </div>
            <div className="bestseller-div mt-5">
                <div className="container">
                    <div className="whats-new-div">
                        <div className="cat-tabs-wrap desk-tabs mt-4">
                            <div className="cat-tabs bg-brand-light d-flex align-items-center rounded-brand w-max-content mx-auto p-2">
                                <div className="tab-back bg-white shadow-sm rounded-brand-sm" style={{ left: sellertab === 1 ? 10 : 170 * (sellertab - 1) }}></div>
                                <p className={`mb-0 me-3 px-3 py-1 body-font-semibold rounded-brand-sm cursor-pointer ${sellertab === 1 ? 'bg-white shadow-sm' : 'text-muted'}`} onClick={() => setSellertab(1)}>BEST SELLER</p>
                                <p className={`mb-0 me-3 px-3 py-1 body-font-semibold rounded-brand-sm cursor-pointer ${sellertab === 2 ? 'bg-white shadow-sm' : 'text-muted'}`} onClick={() => setSellertab(2)}>ON SALE</p>
                                <p className={`mb-0 me-3 px-3 py-1 body-font-semibold rounded-brand-sm cursor-pointer ${sellertab === 3 ? 'bg-white shadow-sm' : 'text-muted'}`} onClick={() => setSellertab(3)}>NEW ARRAIVALS</p>
                            </div>
                        </div>
                        <div className="cat-tabs-wrap mob-tabs mt-4">
                            <div className="cat-tabs bg-brand-light d-flex align-items-center rounded-brand w-max-content mx-auto p-2">
                                <div className="tab-back bg-white shadow-sm rounded-brand-sm" style={{ left: sellertab === 1 ? 10 : 95 * (sellertab - 1) }}></div>
                                <p className={`mb-0 me-3 px-3 py-1 body-font-semibold rounded-brand-sm cursor-pointer ${sellertab === 1 ? 'bg-white shadow-sm' : 'text-muted'}`} onClick={() => setSellertab(1)}>BEST</p>
                                <p className={`mb-0 me-3 px-3 py-1 body-font-semibold rounded-brand-sm cursor-pointer ${sellertab === 2 ? 'bg-white shadow-sm' : 'text-muted'}`} onClick={() => setSellertab(2)}>SALE</p>
                                <p className={`mb-0 me-0 px-3 py-1 body-font-semibold rounded-brand-sm cursor-pointer ${sellertab === 3 ? 'bg-white shadow-sm' : 'text-muted'}`} onClick={() => setSellertab(3)}>NEW</p>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    sellertab === 1 ? (
                        <div className="container-fluid">
                            <div className="whats-new-card-wrap mt-3 pb-5">
                                <div className="new-cards row">
                                {
                                    prodata.length > 0 && (
                                        prodata.reverse().slice(0, 4).map((pro, ind) => (
                                            <div key={ind} className="col-md-3 col-6 mb-4">
                                                <Productcard coverimg={splitFunc(pro.colornimage[0].url)[0]} hoverimg={splitFunc(pro.colornimage[0].url)[1]} tag={pro.status} saletxt={pro.spinningtxt} name={pro.name} price={pro.discountprice} preprice={pro.price} id={pro.proid} />
                                            </div>
                                        ))
                                    )
                                }
                                </div>
                                <div className="mx-auto w-max-content mt-3 cursor-pointer">
                                    <Link to="/product" className="btn btn-brand-light bg-brand-light mx-auto body-font-semibold px-3 rounded-brand-sm">VIEW ALL</Link>
                                </div>
                            </div>
                        </div>
                    ) : (
                        sellertab === 2 ? (
                            <div className="container-fluid">
                                <div className="whats-new-card-wrap mt-3 pb-5">
                                    <div className="new-cards row">
                                    {
                                        prodata.length > 0 && (
                                            prodata.filter(e => e.status === 'Sale').map((pro, ind) => (
                                                <div key={ind} className="col-md-3 col-6 mb-4">
                                                    <Productcard coverimg={splitFunc(pro.colornimage[0].url)[0]} hoverimg={splitFunc(pro.colornimage[0].url)[1]} tag={pro.status} saletxt={pro.spinningtxt} name={pro.name} price={pro.discountprice} preprice={pro.price} id={pro.proid} />
                                                </div>
                                            ))
                                        )
                                    }
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="container-fluid">
                                <div className="whats-new-card-wrap mt-3 pb-5">
                                    <div className="new-cards row">
                                    {
                                        prodata.length > 0 && (
                                            prodata.filter(e => e.status === 'New').map((pro, ind) => (
                                                <div key={ind} className="col-md-3 col-6 mb-4">
                                                    <Productcard coverimg={splitFunc(pro.colornimage[0].url)[0]} hoverimg={splitFunc(pro.colornimage[0].url)[1]} tag={pro.status} saletxt={pro.spinningtxt} name={pro.name} price={pro.discountprice} preprice={pro.price} id={pro.proid} />
                                                </div>
                                            ))
                                        )
                                    }
                                    </div>
                                </div>
                            </div>
                        )
                    )
                }
            </div>


            {/* <div className="container-fluid">
                <div className="new-cards row">
                    {
                        prodata.length > 0 && (
                            prodata.map((pro, ind) => (
                                <div key={ind} className="col-md-3 col-6 mb-4">
                                    <Productcard coverimg={splitFunc(pro.colornimage[0].url)[0]} hoverimg={splitFunc(pro.colornimage[0].url)[1]} tag={pro.status} saletxt={pro.spinningtxt} name={pro.name} price={pro.discountprice} preprice={pro.price} id={pro.proid} />
                                </div>
                            ))
                        )
                    }
                </div>
            </div> */}


            <div className="best-n-new-div container-fluid p-0 row mx-auto justify-content-between pb-5">
                <div className="col-md-6 mb-3">
                    <div className="best-n-new" style={{ backgroundImage: "url(https://www.ikea.com/images/various-rocks-displayed-in-skoenja-glass-domes-on-top-of-a-g-8393fba5fa49d348fb6253d1138e10b7.jpg?f=s)" }}>
                        <div className="best-text-wrap">
                            <p className="mb-0 fs-1 body-font-bold">Best Decor</p>
                            <p className="mb-0 border-bottom border-light body-font-semibold">Shop Now</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 mb-3">
                    <div className="best-n-new" style={{ backgroundImage: "url(https://gembah.com/wp-content/uploads/2022/07/home-kitchen-gadgets-scaled-1.jpeg)" }}>
                        <div className="best-text-wrap">
                            <p className="mb-0 fs-1 body-font-bold">Kitchen Products</p>
                            <p className="mb-0 border-bottom border-light body-font-semibold">Shop Now</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="service-div container-fluid mt-5 mb-5">
                <div className="row">
                    <div className="col-md-3 col-6 mb-4">
                        <div className="d-flex flex-column justify-content-center align-items-center text-center">
                            <img src="/assets/24-7-icon.png" alt="" className="img-fluid service-img mb-4" />
                            <p className="mb-2 body-font-bold fs-5">24/7 Customer Service</p>
                            <p className="mb-0 text-muted body-font-medium">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugit?</p>
                        </div>
                    </div>
                    <div className="col-md-3 col-6 mb-4">
                        <div className="d-flex flex-column justify-content-center align-items-center text-center">
                            <img src="/assets/money-back-icon.png" alt="" className="img-fluid service-img mb-4" />
                            <p className="mb-2 body-font-bold fs-5">24/7 Customer Service</p>
                            <p className="mb-0 text-muted body-font-medium">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugit?</p>
                        </div>
                    </div>
                    <div className="col-md-3 col-6 mb-4">
                        <div className="d-flex flex-column justify-content-center align-items-center text-center">
                            <img src="/assets/guarantee-icon.png" alt="" className="img-fluid service-img mb-4" />
                            <p className="mb-2 body-font-bold fs-5">24/7 Customer Service</p>
                            <p className="mb-0 text-muted body-font-medium">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugit?</p>
                        </div>
                    </div>
                    <div className="col-md-3 col-6 mb-4">
                        <div className="d-flex flex-column justify-content-center align-items-center text-center">
                            <img src="/assets/shipping-icon.png" alt="" className="img-fluid service-img mb-4" />
                            <p className="mb-2 body-font-bold fs-5">24/7 Customer Service</p>
                            <p className="mb-0 text-muted body-font-medium">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugit?</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="review-div">
                <div className="container-fluid py-5 bg-brand-light">
                    <h2 className="fs-2 body-font-bold text-center mb-4">What People Are Saying</h2>
                    <Slider {...reviewcaro}>
                        <div className="p-3">
                            <div className="review-card rounded-brand p-4 bg-white">
                                <p className="mb-0">
                                    <i className="fas fa-star text-warning me-1"></i>
                                    <i className="fas fa-star text-warning me-1"></i>
                                    <i className="fas fa-star text-warning me-1"></i>
                                    <i className="fas fa-star text-warning me-1"></i>
                                    <i className="fas fa-star text-warning me-1"></i>
                                </p>
                                <p className="mt-3 body-font-bold fs-5">
                                    Veriety Of Style!
                                </p>
                                <p className="mb-0 mt-3">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae quae provident itaque eligendi impedit vero.
                                </p>
                                <p className="mb-0 body-font-bold mt-3">Manish Gawade</p>
                                <p className="mb-0 text-muted">28 July 2024</p>
                            </div>
                        </div>
                        <div className="p-3">
                            <div className="review-card rounded-brand p-4 bg-white">
                                <p className="mb-0">
                                    <i className="fas fa-star text-warning me-1"></i>
                                    <i className="fas fa-star text-warning me-1"></i>
                                    <i className="fas fa-star text-warning me-1"></i>
                                    <i className="fas fa-star text-warning me-1"></i>
                                    <i className="fas fa-star text-warning me-1"></i>
                                </p>
                                <p className="mt-3 body-font-bold fs-5">
                                    Veriety Of Style!
                                </p>
                                <p className="mb-0 mt-3">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae quae provident itaque eligendi impedit vero.
                                </p>
                                <p className="mb-0 body-font-bold mt-3">Manish Gawade</p>
                                <p className="mb-0 text-muted">28 July 2024</p>
                            </div>
                        </div>
                        <div className="p-3">
                            <div className="review-card rounded-brand p-4 bg-white">
                                <p className="mb-0">
                                    <i className="fas fa-star text-warning me-1"></i>
                                    <i className="fas fa-star text-warning me-1"></i>
                                    <i className="fas fa-star text-warning me-1"></i>
                                    <i className="fas fa-star text-warning me-1"></i>
                                    <i className="fas fa-star text-warning me-1"></i>
                                </p>
                                <p className="mt-3 body-font-bold fs-5">
                                    Veriety Of Style!
                                </p>
                                <p className="mb-0 mt-3">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae quae provident itaque eligendi impedit vero.
                                </p>
                                <p className="mb-0 body-font-bold mt-3">Manish Gawade</p>
                                <p className="mb-0 text-muted">28 July 2024</p>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
            <div className="container-fluid py-5 mt-5 d-none">
                <div className="strip-wraper d-flex">
                    <div className="logo-slide-wrap d-flex align-items-center text-white w-max-content">
                        <img src="https://images.squarespace-cdn.com/content/v1/642e8ff960d5cc49a82c87e5/f66abcb0-6364-4f87-94c4-a2d0a2afb8ad/Grayscale+logos-04.png" alt="" className="logo-slide-img" />
                        <img src="https://images.squarespace-cdn.com/content/v1/642e8ff960d5cc49a82c87e5/f66abcb0-6364-4f87-94c4-a2d0a2afb8ad/Grayscale+logos-04.png" alt="" className="logo-slide-img" />
                        <img src="https://images.squarespace-cdn.com/content/v1/642e8ff960d5cc49a82c87e5/f66abcb0-6364-4f87-94c4-a2d0a2afb8ad/Grayscale+logos-04.png" alt="" className="logo-slide-img" />
                        <img src="https://images.squarespace-cdn.com/content/v1/642e8ff960d5cc49a82c87e5/f66abcb0-6364-4f87-94c4-a2d0a2afb8ad/Grayscale+logos-04.png" alt="" className="logo-slide-img" />
                        <img src="https://images.squarespace-cdn.com/content/v1/642e8ff960d5cc49a82c87e5/f66abcb0-6364-4f87-94c4-a2d0a2afb8ad/Grayscale+logos-04.png" alt="" className="logo-slide-img" />
                        <img src="https://images.squarespace-cdn.com/content/v1/642e8ff960d5cc49a82c87e5/f66abcb0-6364-4f87-94c4-a2d0a2afb8ad/Grayscale+logos-04.png" alt="" className="logo-slide-img" />
                    </div>
                    <div className="logo-slide-wrap d-flex align-items-center text-white w-max-content">
                        <img src="https://images.squarespace-cdn.com/content/v1/642e8ff960d5cc49a82c87e5/f66abcb0-6364-4f87-94c4-a2d0a2afb8ad/Grayscale+logos-04.png" alt="" className="logo-slide-img" />
                        <img src="https://images.squarespace-cdn.com/content/v1/642e8ff960d5cc49a82c87e5/f66abcb0-6364-4f87-94c4-a2d0a2afb8ad/Grayscale+logos-04.png" alt="" className="logo-slide-img" />
                        <img src="https://images.squarespace-cdn.com/content/v1/642e8ff960d5cc49a82c87e5/f66abcb0-6364-4f87-94c4-a2d0a2afb8ad/Grayscale+logos-04.png" alt="" className="logo-slide-img" />
                        <img src="https://images.squarespace-cdn.com/content/v1/642e8ff960d5cc49a82c87e5/f66abcb0-6364-4f87-94c4-a2d0a2afb8ad/Grayscale+logos-04.png" alt="" className="logo-slide-img" />
                        <img src="https://images.squarespace-cdn.com/content/v1/642e8ff960d5cc49a82c87e5/f66abcb0-6364-4f87-94c4-a2d0a2afb8ad/Grayscale+logos-04.png" alt="" className="logo-slide-img" />
                        <img src="https://images.squarespace-cdn.com/content/v1/642e8ff960d5cc49a82c87e5/f66abcb0-6364-4f87-94c4-a2d0a2afb8ad/Grayscale+logos-04.png" alt="" className="logo-slide-img" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;