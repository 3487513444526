import React, { useEffect, useState } from 'react';
import './Listing.css';
import Productcard from '../../Components/Productcard/Productcard';
import { Link, useParams } from 'react-router-dom';

function Listing() {

    const {cate} = useParams();

    // const [drop, setDrop] = useState('none');
    // const [dropval, setDropval] = useState('Category');
    // const [minrange, setMinrange] = useState(0);
    // const [maxrange, setMaxrange] = useState(10000);
    const [allpro, setAllpro] = useState([]);
    const [temppro, setTemppro] = useState([]);
    // const [filterdis, setFilterdis] = useState('none');

    useEffect(()=> window.scrollTo(0, 0), []);

    // useEffect(()=> {
    //     if(window.innerWidth > 800){
    //         setFilterdis('block')
    //     }else{
    //         setFilterdis('none')
    //     }
    // }, []);

    // useEffect(()=> {
    //     console.log(minrange, maxrange);
    // }, [minrange, maxrange]);

    useEffect(()=> {
        fetch(`https://homejoy.onrender.com`).then(res => res.json()).then(data => {
            if(data.success){
                setTemppro(data.pro);
                setAllpro(data.pro);
                console.log(data);
            }else{
                console.log(data);
            }
        }).catch(err => console.log('error occured while getting all the pros, ', err));
    }, []);

    useEffect(()=> {
        console.log(cate);
        if(cate && cate?.length > 0){
            const newArr = temppro?.filter(e => e.category.toLowerCase() === cate);
            setAllpro(newArr);
        }else{
            setAllpro(temppro);
        }
    }, [cate, temppro])

    const splitFunc = (str)=> {
        const imgFst = str.split(',');
        return imgFst;
    }

  return (
    <div className='main-lising'>
        <div className="container-fluid bg-brand-light pt-5 pb-3">
            <div className="heading-wrap pt-5">
                <h2 className="heading-text fs-1 body-font-bold text-center mb-3">All Products</h2>
                <p className="mb-4 text-center body-font-semibold">Homepage {'>'} <span className="text-muted body-font-semibold">Product</span></p>
                <div className="listing-tabs d-flex flex-wrap justify-content-center mx-auto mt-5">
                    <p className={`mb-3 pb-1 me-4 text-uppercase ${window.location.pathname === "/product"? "border-bottom border-dark border-2": ""}`}><Link to="/product" className='nav-link'>All</Link></p>
                    <p className={`mb-3 pb-1 me-4 text-uppercase ${cate === "home"? "border-bottom border-dark border-2": ""}`}><Link to='/product/home' className='nav-link'>Home</Link></p>
                    <p className={`mb-3 pb-1 me-4 text-uppercase ${cate === "kitchen"? "border-bottom border-dark border-2": ""}`}><Link to='/product/kitchen' className='nav-link'>Kitchen</Link></p>
                    <p className={`mb-3 pb-1 me-4 text-uppercase ${cate === "bathroom"? "border-bottom border-dark border-2": ""}`}><Link to='/product/bathroom' className='nav-link'>Bathroom</Link></p>
                    <p className={`mb-3 pb-1 me-4 text-uppercase ${cate === "cleaning"? "border-bottom border-dark border-2": ""}`}><Link to='/product/cleaning' className='nav-link'>Cleaning</Link></p>
                    <p className={`mb-3 pb-1 me-0 text-uppercase ${cate === "kids"? "border-bottom border-dark border-2": ""}`}><Link to='/product/kids' className='nav-link'>Kids</Link></p>
                </div>
            </div>
        </div>
        <div className="container-fluid mt-4 pt-0">
            <div className="product-n-filter">
                {/* <div className="col-md-3 d-none filter-side mb-5" style={{display: filterdis}}>
                    <div className="product-type border-bottom pb-4">
                        <div className="justify-content-end filter-cross"><p className="text-muted mb-3" onClick={()=> setFilterdis("none")}><i className="fas fa-times"></i></p></div>
                        <h2 className="fs-4 body-font-bold mb-4">Product Type</h2>
                        <div className="row">
                            <div className="col-md-10">
                                <div className="d-flex justify-content-between">
                                    <p className="mb-3 text-muted text-capitalize body-font-semibold">hand bag</p> <span className="text-muted body-font-medium">(12)</span>
                                </div>
                            </div>
                            <div className="col-md-10">
                                <div className="d-flex justify-content-between">
                                    <p className="mb-3 text-muted text-capitalize body-font-semibold">purse</p> <span className="text-muted body-font-medium">(12)</span>
                                </div>
                            </div>
                            <div className="col-md-10">
                                <div className="d-flex justify-content-between">
                                    <p className="mb-3 text-muted text-capitalize body-font-semibold">bag</p> <span className="text-muted body-font-medium">(12)</span>
                                </div>
                            </div>
                            <div className="col-md-10">
                                <div className="d-flex justify-content-between">
                                    <p className="mb-3 text-muted text-capitalize body-font-semibold">tote bag</p> <span className="text-muted body-font-medium">(12)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="price-range pt-4 pb-4 border-bottom">
                        <h2 className="fs-4 body-font-bold mb-4">Price Range</h2>
                        <div className="price-range-input d-flex">
                            <span className="price-range-tracker" style={{background: `linear-gradient(to right, lightgray 0%, lightgray ${minrange/10000*100}%, var(--dark) ${minrange/10000*100 }%, var(--dark) ${maxrange/10000*100 }%, lightgray ${maxrange/10000*100 }%, lightgray 100%)`}}></span>
                            <input type="range" name="min-range"  id="min-range" value={minrange} onChange={(e)=> {setMinrange(e.target.value > +maxrange - 1000? +maxrange - 1000: e.target.value)}} className='min-range range-input' min={0} max={10000} />
                            <input type="range" name="max-range"  id="max-range" value={maxrange} onChange={(e)=> {setMaxrange(e.target.value < +minrange + 1000? +minrange + 1000: e.target.value)}} className='max-range range-input' min={1000} max={10000} />
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-4">
                            <p className="mb-0">Min price: <span className="body-font-semibold">₹{minrange}</span></p>
                            <p className="mb-0">Max price: <span className="body-font-semibold">₹{maxrange}</span></p>
                        </div>
                    </div>
                    <div className="color-choise mt-4">
                        <h2 className="fs-4 body-font-bold mb-4">Color</h2>
                        <div className="d-flex flex-wrap">
                            <div className="mb-2 d-flex align-items-center rounded-pill border border-dark px-2 py-2 me-2 text-capitalize">
                                <div className="filter-color me-2" style={{backgroundColor: 'red'}}><div className='overlay'></div></div> <p className="mb-0">Red</p>
                            </div>
                            <div className="mb-2 d-flex align-items-center rounded-pill border border-dark px-2 py-2 me-2 text-capitalize">
                                <div className="filter-color me-2" style={{backgroundColor: 'orange'}}><div className='overlay'></div></div> <p className="mb-0">Orange</p>
                            </div>
                            <div className="mb-2 d-flex align-items-center rounded-pill border border-dark px-2 py-2 me-2 text-capitalize">
                                <div className="filter-color me-2" style={{backgroundColor: 'yellow'}}><div className='overlay'></div></div> <p className="mb-0">yellow</p>
                            </div>
                            <div className="mb-2 d-flex align-items-center rounded-pill border border-dark px-2 py-2 me-2 text-capitalize">
                                <div className="filter-color me-2" style={{backgroundColor: 'aqua'}}><div className='overlay'></div></div> <p className="mb-0">aqua</p>
                            </div>
                            <div className="mb-2 d-flex align-items-center rounded-pill border border-dark px-2 py-2 me-2 text-capitalize">
                                <div className="filter-color me-2" style={{backgroundColor: 'blue'}}><div className='overlay'></div></div> <p className="mb-0">blue</p>
                            </div>
                        </div>
                    </div>
                    <div className="extra-filter desk-extra">
                        <div className="d-flex flex-wrap justify-content-between align-items-end mb-1">
                            <div className="only-sale-pros d-flex mb-3">
                                <input type="checkbox" name="onlysale" id="onlysale" className='shadow-none outline-none w-max-content me-2 border cursor-pointer' /> <label htmlFor="onlysale" className='cursor-pointer'>Show only products on sale</label>
                            </div>
                            <div className="drop-wrap mb-3" onClick={()=> setDrop(drop === 'none'? 'block': 'none')}>
                                <div className="filter-dropdown-wrap cursor-pointer">
                                    <div className="d-flex justify-content-between border p-2 rounded-brand-sm">
                                        <p className="p-0 m-0 me-5 pe-5">{dropval}</p>
                                        <p className="mb-1 text-muted"><i className="fas fa-sort-down"></i></p>
                                    </div>
                                </div>
                                <div className="drop-options mt-2 p-2 rounded-brand bg-white w-max-content" style={{display: drop}}>
                                    <p className="mb-1 rounded-brand-sm border px-2 cursor-pointer text-capitalize bg-light" onClick={(e)=> setDropval(e.target.innerText)}>Filter</p>
                                    <p className="mb-1 rounded-brand-sm border px-2 cursor-pointer text-capitalize bg-light" onClick={(e)=> setDropval(e.target.innerText)}>Sale</p>
                                    <p className="mb-1 rounded-brand-sm border px-2 cursor-pointer text-capitalize bg-light" onClick={(e)=> setDropval(e.target.innerText)}>best seller</p>
                                    <p className="mb-1 rounded-brand-sm border px-2 cursor-pointer text-capitalize bg-light" onClick={(e)=> setDropval(e.target.innerText)}>best discount</p>
                                    <p className="mb-1 rounded-brand-sm border px-2 cursor-pointer text-capitalize bg-light" onClick={(e)=> setDropval(e.target.innerText)}>price: low - hight</p>
                                    <p className="mb-1 rounded-brand-sm border px-2 cursor-pointer text-capitalize bg-light" onClick={(e)=> setDropval(e.target.innerText)}>price: hight - low</p>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap align-items-center mb-3">
                            <div>
                                <p className="text-muted border-end pe-3 me-3 w-max-content"><span className='text-dark body-font-semibold'>{allpro?.length}</span> Products Found</p>
                            </div>
                            <div className="d-flex flex-wrap">
                                <p className="mb-3 me-3 rounded-pill px-3 bg-brand-light body-font-semibold py-2">T-Shirts <span className="ms-2 text-muted"><i className="fas fa-times"></i></span></p>
                                <p className="mb-3 me-3 rounded-pill px-3 bg-brand-light body-font-semibold py-2">Red <span className="ms-2 text-muted"><i className="fas fa-times"></i></span></p>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="main-listing">
                    <div className="extra-filter extra-filter-out">
                        {/* <div className="d-flex flex-wrap justify-content-between align-items-end mb-1">
                            <div className="only-sale-pros d-flex mb-3">
                                <input type="checkbox" name="onlysale" id="onlysale" className='shadow-none outline-none w-max-content me-2 border cursor-pointer' /> <label htmlFor="onlysale" className='cursor-pointer'>Show only products on sale</label>
                            </div>
                            <div className="drop-wrap mb-3" onClick={()=> setDrop(drop === 'none'? 'block': 'none')}>
                                <div className="filter-dropdown-wrap cursor-pointer">
                                    <div className="d-flex justify-content-between border p-2 rounded-brand-sm">
                                        <p className="p-0 m-0 me-5 pe-5">{dropval}</p>
                                        <p className="mb-1 text-muted"><i className="fas fa-sort-down"></i></p>
                                    </div>
                                </div>
                                <div className="drop-options mt-2 p-2 rounded-brand bg-white w-max-content" style={{display: drop}}>
                                    <p className="mb-1 rounded-brand-sm border px-2 cursor-pointer text-capitalize bg-light" onClick={(e)=> setDropval(e.target.innerText)}>Filter</p>
                                    <p className="mb-1 rounded-brand-sm border px-2 cursor-pointer text-capitalize bg-light" onClick={(e)=> setDropval(e.target.innerText)}>Sale</p>
                                    <p className="mb-1 rounded-brand-sm border px-2 cursor-pointer text-capitalize bg-light" onClick={(e)=> setDropval(e.target.innerText)}>best seller</p>
                                    <p className="mb-1 rounded-brand-sm border px-2 cursor-pointer text-capitalize bg-light" onClick={(e)=> setDropval(e.target.innerText)}>best discount</p>
                                    <p className="mb-1 rounded-brand-sm border px-2 cursor-pointer text-capitalize bg-light" onClick={(e)=> setDropval(e.target.innerText)}>price: low - hight</p>
                                    <p className="mb-1 rounded-brand-sm border px-2 cursor-pointer text-capitalize bg-light" onClick={(e)=> setDropval(e.target.innerText)}>price: hight - low</p>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="d-flex flex-wrap align-items-center mb-3">
                            <div>
                                <p className="text-muted border-end pe-3 me-3 w-max-content"><span className='text-dark body-font-semibold'>{allpro?.length}</span> Products Found</p>
                            </div>
                            <div className="d-flex flex-wrap">
                                <p className="mb-3 me-3 rounded-pill px-3 bg-brand-light body-font-semibold py-2">T-Shirts <span className="ms-2 text-muted"><i className="fas fa-times"></i></span></p>
                                <p className="mb-3 me-3 rounded-pill px-3 bg-brand-light body-font-semibold py-2">Red <span className="ms-2 text-muted"><i className="fas fa-times"></i></span></p>
                            </div>
                        </div> */}
                    </div>
                    {/* <div className="filter-icon mb-4" onClick={()=> setFilterdis('flex')}>
                        <p className="mb-3 px-3 py-2 rounded-brand-sm border w-max-content text-muted"><i className="fas fa-filter me-2"></i> Filter</p>
                    </div> */}
                    <div className="row">
                        {
                            allpro?.length > 0 && allpro?.map((pro, ind) => (
                                <div key={ind} className="col-md-3 col-6 mb-5">
                                    <Productcard coverimg={splitFunc(pro.colornimage[0].url)[0]} hoverimg={splitFunc(pro.colornimage[0].url)[1]} tag={pro.status} saletxt={pro.spinningtxt} name={pro.name} price={pro.discountprice} preprice={pro.price} id={pro.proid} />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Listing