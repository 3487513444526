import React, { useEffect, useState } from 'react';
import './Dashboard.css';

function Dashboard() {

    const [starcount, setStarcount] = useState(0);
    const [catopt, setCatopt] = useState('none');
    const [catval, setCatval] = useState('Category');
    const [tagarr, setTagarr] = useState([]);
    const [colornurl, setColornurl] = useState([{color: '#ffffff', url: ''}]);
    const [loaderdis, setLoaderdis] = useState('none');
    const [poptxt, setPoptxt] = useState('');
    const [poperr, setPoperr] = useState('');
    const [statustag, setStatustag] = useState('');
    const [features, setFeatures] = useState([{name: "", description: ""}]);
    const [formdata, setFormdata] = useState({
        name: "",
        tag: [],
        category: "",
        price: 0,
        discountprice: 0,
        description: "",
        noreviews: 0,
        stars: 0,
        status: statustag !== ''? statustag: '', 
        spinningtxt: '',
        features: {
            name: '',
            description: ''
        },
        colornimage: [{
            color: "",
            url: ""
        }],
        buylink: ""
    });

    const tagFunc = (e)=> {
        const val = e.target.innerText;
        if(tagarr.includes(val)){
            const arrind = tagarr.indexOf(val)
            const newarr = [...tagarr];
            newarr.splice(arrind, 1)
            setTagarr(newarr);
            console.log('exists', val);
        }else{
            setTagarr([...tagarr, val]);
            console.log('does not exists', val);
        }
    }

    const statustagFunc = (e) => {
        const val = e.target.innerText
        if(statustag === val){
            setStatustag('');
        }else{
            setStatustag(val);
        }
    }

    const addNewColornurl = () => {
        const newColornurl = colornurl;
        setColornurl([...newColornurl, {color: '', url: ''}])
    }

    const colorChangeFunc = (e, ind) => {
        const val = e.target.value;
        const newarr = [...colornurl];
        newarr[ind].color = val;
        setColornurl([...newarr]);
    }

    const urlChangeFunc = (e, ind, urlIndex) => {
        const val = e.target.value;
        const newarr = [...colornurl];
        let urlsArray = newarr[ind].url ? newarr[ind].url.split(',') : ['', '', '', ''];
        urlsArray[urlIndex] = val; // Update the URL at the specified index
        newarr[ind].url = urlsArray.join(','); // Join the array back into a string
        setColornurl([...newarr]);
    }
    // const urlChangeFunc = (e, ind) => {
    //     const val = e.target.value;
    //     const newarr = [...colornurl];
    //     newarr[ind].url = val;
    //     setColornurl([...newarr]);
    // }

    const colorDelFunc = (ind) => {
        const newColor = [...colornurl];
        newColor.splice(ind, 1);
        setColornurl([...newColor])
    }

    const addFeatures = () => {
        const temparr = [...features]
        setFeatures([...temparr, {name: "", description: ""}]);
    }

    const featNameChange = (e, ind) => {
        const val = e.target.value;
        const newarr = [...features];
        newarr[ind].name = val;
        setFeatures([...newarr]);
    }

    const featDescChange = (e, ind) => {
        const val = e.target.value;
        const newarr = [...features];
        newarr[ind].description = val;
        setFeatures([...newarr]);
    }

    const featDelFunc = (ind) => {
        const newarr = [...features];
        if(newarr.length > 1){
            newarr.splice(ind, 1);
            setFeatures([...newarr]);
        }
    }

    useEffect(()=> console.log(features), [features]);

    useEffect(()=> console.log(colornurl), [colornurl]);
    useEffect(()=> console.log(formdata), [formdata]);

    useEffect(() => {
        setFormdata(x => ({...x, tag: tagarr}))
    }, [tagarr])
    useEffect(() => {
        setFormdata(x => ({...x, colornimage: colornurl}))
    }, [colornurl])
    useEffect(() => {
        setFormdata(x => ({...x, category: catval}))
    }, [catval])
    useEffect(() => {
        setFormdata(x => ({...x, stars: starcount}))
    }, [starcount])
    useEffect(() => {
        setFormdata(x => ({...x, features: features}))
    }, [features])
    useEffect(() => {
        setFormdata(x => ({...x, status: statustag}))
    }, [statustag]);

    const validateFormData = () => {
        const { name, category, price, discountprice, description, features, colornimage, buylink } = formdata;
    
        if (!name || !discountprice || !category || !price || !description || !buylink || colornimage.length === 0 || features.length === 0) {
            setPoperr("Please fill all required fields.");
            setTimeout(() => {
                setPoperr('');
            }, 3000);
            return false;
        }
    
        // Validate nested arrays (colornimage and features)
        const invalidColor = colornimage.some(item => !item.color || !item.url);
        const invalidFeature = features.some(item => !item.name || !item.description);
    
        if (invalidColor) {
            setPoperr("Please complete all color and image fields.");
            setTimeout(() => {
                setPoperr('');
            }, 3000);
            return false;
        }
    
        if (invalidFeature) {
            setPoperr("Please complete all feature fields.");
            setTimeout(() => {
                setPoperr('');
            }, 3000);
            return false;
        }
    
        return true;
    };
    

    const uploadFunc = ()=> {

        const isvalid = validateFormData();
        if(isvalid){
            setLoaderdis('flex');
            fetch(`https://homejoy.onrender.com/uploadproduct`, {
                method: 'POST',
                headers:{
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formdata)
            }).then(res => res.json()).then(data => {
                if(data.success){
                    setLoaderdis('none');
                    setPoptxt("Product uploaded successfully");
                    setTimeout(() => {
                        setPoptxt('');
                    }, 3000);
                    console.log(data);
                }
            }).catch(err => console.log('error occured while uploading the data ', err));
        }
    }

  return (
    <div className='main-dashboard'>
        <div className="container upload-form-wrap mt-4 mb-5">
            <h2 className="fs-4 body-font-bold border-bottom pb-3">Upload Products</h2>
            <div className="form-wrap mt-3">

                <p className="mb-2 body-font-semibold">Product name</p>
                <input type="text" name="name" id="name" className='form-control outline-none shadow-none border rounded-brand-sm' placeholder='Product name' value={formdata.name} onChange={(e)=> setFormdata(x => ({...x, name: e.target.value}))}/>

                <div className="mt-4">
                    <p className="mb-2 body-font-bold fs-5">Price</p>
                    <div className="d-flex">
                        <div className="price">
                            <p className="mb-2 body-font-semibold">Before discount</p>
                            <input type="number" name="beforeprice" className='form-control outline-none shadow-none border rounded-brand-sm' value={formdata.price} onChange={(e)=> setFormdata(x => ({...x, price: e.target.value}))}/>
                        </div>
                        <div className="price ps-3">
                            <p className="mb-2 body-font-semibold">After discount</p>
                            <input type="number" name="afterprice"  className='form-control outline-none shadow-none border rounded-brand-sm' value={formdata.discountprice} onChange={(e)=> setFormdata(x => ({...x, discountprice: e.target.value}))}/>
                        </div>
                    </div>
                </div>

                <p className="mb-2 body-font-semibold mt-4">Spinning text <span className="text-muted">(Optional)</span></p>
                <input type="text" name="spinningtxt" id="spinningtxt" className='form-control outline-none shadow-none border rounded-brand-sm' placeholder='Spinning text' value={formdata.spinningtxt} onChange={(e)=> setFormdata(x => ({...x, spinningtxt: e.target.value}))}/>

                <p className="mb-2 body-font-semibold mt-4">Product Description</p>
                <textarea type="text" name="description" rows={3} className='form-control outline-none shadow-none border rounded-brand-sm' placeholder='Product Description' value={formdata.description} onChange={(e)=> setFormdata(x => ({...x, description: e.target.value}))}></textarea>

                <div className="numberofstars mt-4">
                    <p className="mb-2 body-font-bold fs-5">Rating & reviews</p>
                    <div className="d-flex rating-wrap align-items-center justify-content-between">
                        <div className="d-flex">
                            <p className="mb-0 me-2 fs-3 cursor-pointer" onClick={()=> setStarcount(1)}><i className={`fas fa-${starcount > 0? 'star text-warning': 'star text-muted'}`}></i></p>
                            <p className="mb-0 me-2 fs-3 cursor-pointer" onClick={()=> setStarcount(2)}><i className={`fas fa-${starcount > 1? 'star text-warning': 'star text-muted'}`}></i></p>
                            <p className="mb-0 me-2 fs-3 cursor-pointer" onClick={()=> setStarcount(3)}><i className={`fas fa-${starcount > 2? 'star text-warning': 'star text-muted'}`}></i></p>
                            <p className="mb-0 me-2 fs-3 cursor-pointer" onClick={()=> setStarcount(4)}><i className={`fas fa-${starcount > 3? 'star text-warning': 'star text-muted'}`}></i></p>
                            <p className="mb-0 me-2 fs-3 cursor-pointer" onClick={()=> setStarcount(5)}><i className={`fas fa-${starcount > 4.5? 'star text-warning': 'star text-muted'}`}></i></p>
                        </div>
                        <input type="number" name="nostars" value={starcount} onChange={(e)=> setStarcount(e.target.value)} className='nostars form-control outline-none shadow-none border rounded-brand-sm' />
                        <input type="number" name="noreviews" className='noreviews form-control outline-none shadow-none border rounded-brand-sm text-center' placeholder='reviews' value={formdata.noreviews} onChange={(e)=> setFormdata(x => ({...x, noreviews: e.target.value}))}/>
                    </div>
                </div>

                <div className="category-wrap mt-4">
                    <p className="mb-2 body-font-bold fs-5">Product category</p>
                    <div className="d-flex justify-content-between align-items-center border rounded-brand-sm p-2 cursor-pointer" onClick={() => setCatopt(catopt === 'none'? 'block': 'none')}>
                        <input type="text" name="category" id="category" className='form-control text-capitalize shadow-none outline-none border-0 p-0 m-0 cursor-pointer' value={catval} onChange={(e)=> setCatval(e.target.value)} />
                        <i className="fas fa-sort-down text-muted fs-5 mb-1 me-2"></i>
                    </div>
                    <div className="category-options-wrap" style={{display: catopt}}>
                        <p className="mb-0 bg-brand-light p-2 text-capitalize cursor-pointer" onClick={(e)=> {setCatopt('none'); setCatval(e.target.innerText)}}>home</p>
                        <p className="mb-0 bg-brand-light p-2 text-capitalize cursor-pointer" onClick={(e)=> {setCatopt('none'); setCatval(e.target.innerText)}}>bathroom</p>
                        <p className="mb-0 bg-brand-light p-2 text-capitalize cursor-pointer" onClick={(e)=> {setCatopt('none'); setCatval(e.target.innerText)}}>storage</p>
                        <p className="mb-0 bg-brand-light p-2 text-capitalize cursor-pointer" onClick={(e)=> {setCatopt('none'); setCatval(e.target.innerText)}}>kitchen</p>
                        <p className="mb-0 bg-brand-light p-2 text-capitalize cursor-pointer" onClick={(e)=> {setCatopt('none'); setCatval(e.target.innerText)}}>cleaning</p>
                        <p className="mb-0 bg-brand-light p-2 text-capitalize cursor-pointer" onClick={(e)=> {setCatopt('none'); setCatval(e.target.innerText)}}>kids</p>
                    </div>
                </div>

                <div className="tag-wrap mt-4">
                    <p className="mb-2 body-font-bold fs-5">Tags</p>
                    <div className="tag-tab-wrap d-flex flex-wrap">
                        <p className={`rounded-brand-sm text-capitalize px-3 py-2 me-2 mb-2 cursor-pointer ${tagarr.includes('Tage 1')? 'bg-dark text-white': 'border border-dark'}`} onClick={(e)=> tagFunc(e)}>Tage 1</p>
                        <p className={`rounded-brand-sm text-capitalize px-3 py-2 me-2 mb-2 cursor-pointer ${tagarr.includes('Tage 2')? 'bg-dark text-white': 'border border-dark'}`} onClick={(e)=> tagFunc(e)}>Tage 2</p>
                        <p className={`rounded-brand-sm text-capitalize px-3 py-2 me-2 mb-2 cursor-pointer ${tagarr.includes('Tage 3')? 'bg-dark text-white': 'border border-dark'}`} onClick={(e)=> tagFunc(e)}>Tage 3</p>
                        <p className={`rounded-brand-sm text-capitalize px-3 py-2 me-2 mb-2 cursor-pointer ${tagarr.includes('Tage 4')? 'bg-dark text-white': 'border border-dark'}`} onClick={(e)=> tagFunc(e)}>Tage 4</p>
                        <p className={`rounded-brand-sm text-capitalize px-3 py-2 me-2 mb-2 cursor-pointer ${tagarr.includes('Tage 5')? 'bg-dark text-white': 'border border-dark'}`} onClick={(e)=> tagFunc(e)}>Tage 5</p>
                        <p className={`rounded-brand-sm text-capitalize px-3 py-2 me-2 mb-2 cursor-pointer ${tagarr.includes('Tage 6')? 'bg-dark text-white': 'border border-dark'}`} onClick={(e)=> tagFunc(e)}>Tage 6</p>
                    </div>
                </div>

                <div className="tag-wrap mt-4">
                    <p className="mb-2 body-font-bold fs-5">Status</p>
                    <div className="tag-tab-wrap d-flex flex-wrap">
                        <p className={`rounded-brand-sm text-capitalize px-3 py-2 me-2 mb-2 cursor-pointer ${statustag === 'New'? 'bg-dark text-white': 'border border-dark'}`} onClick={(e)=> statustagFunc(e)}>New</p>
                        <p className={`rounded-brand-sm text-capitalize px-3 py-2 me-2 mb-2 cursor-pointer ${statustag === 'Bestseller'? 'bg-dark text-white': 'border border-dark'}`} onClick={(e)=> statustagFunc(e)}>Bestseller</p>
                        <p className={`rounded-brand-sm text-capitalize px-3 py-2 me-2 mb-2 cursor-pointer ${statustag === 'Sale'? 'bg-dark text-white': 'border border-dark'}`} onClick={(e)=> statustagFunc(e)}>Sale</p>
                    </div>
                </div>

                <div className="color-image-wrap mt-4">
                    <p className="mb-2 body-font-bold fs-5">Color & image</p>
                    <div className="inputs-wrap">
                        {
                            colornurl.map((arr, ind)=> (
                                <div key={ind} className="row align-items-center mb-3">
                                    <div className="col-10 mb-2">
                                        <input type="text" className="w-100 form-control border outline-none shadow-none rounded-brand-sm" placeholder='Color' value={arr.color} onChange={(e)=> colorChangeFunc(e, ind)}/>
                                    </div>
                                    <div className="col-2 mb-2 ps-0">
                                        <input type="color" value={arr.color} onChange={(e)=> colorChangeFunc(e, ind)} className='rounded-circle cursor-pointer' />
                                    </div>
                                    {/* <div className="col-11 mb-2">
                                        <input type="text" className="w-100 form-control border outline-none shadow-none rounded-brand-sm" placeholder='Image URL' value={arr.url} onChange={(e)=> urlChangeFunc(e, ind)}/>
                                    </div> */}
                                    {
                                        [0, 1, 2, 3].map((urlIndex) => (
                                            <div key={urlIndex} className="col-11 mb-2">
                                                <input 
                                                    type="text" 
                                                    className="w-100 form-control border outline-none shadow-none rounded-brand-sm" 
                                                    placeholder={`Image URL ${urlIndex + 1}`} 
                                                    value={arr.url.split(',')[urlIndex] || ''} 
                                                    onChange={(e) => urlChangeFunc(e, ind, urlIndex)} 
                                                />
                                            </div>
                                        ))
                                    }
                                    <div className="col-1 text-center ps-0 mb-2" onClick={()=> colorDelFunc(ind)}>
                                        <p className="mb-0"><i className="fas fa-trash-alt text-muted"></i></p>
                                    </div>
                                </div>
                            ))
                        }
                        <button className="btn btn-outline-dark px-3" onClick={addNewColornurl}>Add more </button>
                    </div>
                </div>

                <div className="features-wrap mt-4">
                    <p className="mb-2 body-font-bold fs-5">Features</p>
                    <div className="features-input">
                        {
                            features.map((feat, ind) => (
                                <div key={ind} className="row mb-3 align-items-center">
                                    <div className="col-4 pe-0">
                                        <input type="text" className="w-100 border form-control rounded-brand-sm shadow-none outline-none" placeholder='Feature' value={feat.name} onChange={(e)=> featNameChange(e, ind)} />
                                    </div>
                                    <div className="col-7 pe-2">
                                        <input type="text" className="w-100 border form-control rounded-brand-sm shadow-none outline-none" placeholder='Description' value={feat.description} onChange={(e)=> featDescChange(e, ind)} />
                                    </div>
                                    <div className="col-1 text-center ps-0" onClick={()=> featDelFunc(ind)}>
                                        <p className="mb-0"><i className="fas fa-trash-alt text-muted ps-1"></i></p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <button className="btn btn-outline-dark px-3 py-2 rounded-brand-sm" onClick={addFeatures}>Add More</button>
                </div>

                <div className="buylink-wrap mt-4">
                    <p className="mb-2 body-font-bold fs-5">Buy link</p>
                    <input type="text" className='form-control shadow-none outline-none border rounded-brand-sm' placeholder='Product buy link' value={formdata.buylink} onChange={(e)=> setFormdata(x => ({...x, buylink: e.target.value}))}/>
                </div>

                <button className="btn btn-dark mt-4" onClick={uploadFunc}>Upload product</button>

            </div>
        </div>

        <div className="full-overlay" style={{display: loaderdis}}>
            <div className="loader-wrap rounded-brand p-4">
                <div className="loader-roundedr"></div>
            </div>
        </div>

        <div className="position-fixed w-100 p-2 pb-3 bottom-0" style={{display: poptxt === ''? 'none': 'block'}}>
            <p className="mb-0 p-2 bg-success-subtle text-success body-font-bold rounded-brand-sm border border-success border-2 text-capitalize">
                {poptxt}
            </p>
        </div>
        <div className="position-fixed w-100 p-2 pb-3 bottom-0" style={{display: poperr === ''? 'none': 'block'}}>
            <p className="mb-0 p-2 bg-danger-subtle text-danger body-font-bold rounded-brand-sm border border-danger border-2 text-capitalize">
                {poperr}
            </p>
        </div>

    </div>
  )
}

export default Dashboard;