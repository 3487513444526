import React, { useEffect, useState } from 'react';
import './Orders.css';
import { Link } from 'react-router-dom';

function Orders() {

    const [allorder, setAllorder] = useState([]);
    const [tabind, setTabind] = useState(0);
    const [fildata, setFildata] = useState([]);
    const [leads, setLeads] = useState([]);
    const [sortarr, setSortarr] = useState([]);

    useEffect(()=> {
        fetch(`https://homejoy.onrender.com/fororders`).then(res => res.json()).then(data => {
            if(data.success){
                setAllorder(data.pro.reverse());
                setFildata(data.pro.reverse());
                console.log(data);
            }else{
                console.log(data);
            }
        }).catch(err => console.log('error occured while getting all the orders in the order page ', err));
    }, []);

    useEffect(() => {
        fetch(`https://homejoy.onrender.com/leads`).then(res => res.json()).then(data => {
            if(data.success){
                setLeads(data.pro.reverse());
            }else{
                console.log("success is not true ", data);
            }
        }).catch(err => console.log("error occured while getting all the leads ", err));
    }, []);

    useEffect(() => {
        const mergedArr = [...leads, ...allorder];
        const sortedArr = mergedArr.sort((a,b) => {
            const dateA = new Date(a.orderdate || a.cartdate);
            const dateB = new Date(b.orderdate || b.cartdate);
            return dateB - dateA;
        });
        setSortarr(sortedArr);
    }, [leads, allorder]);

    const dateFunc = (date) => {
        const newdate = new Date(date);
        const formateddate = `${newdate.getDate()}/${newdate.getMonth()}/${newdate.getFullYear()}, ${newdate.getHours()}:${newdate.getMinutes()}`
        return formateddate;
    }

    useEffect(()=> {
        if(allorder){
            if(tabind === 1){
                const amazonfilter = allorder?.filter(e => e.platform.toLowerCase() === 'amazon');
                setFildata(amazonfilter);
            }else if(tabind === 2){
                const whatsappfilter = allorder?.filter(e => e.platform.toLowerCase() === 'whatsapp');
                setFildata(whatsappfilter);
            }else if(tabind === 3){
                setFildata(leads);
            }else{
                setFildata(sortarr)
            }
        }
    }, [tabind, allorder, leads, sortarr]);

  return (
    <div className='main-orders'>
        <div className="container-fluid">
            <h2 className="body-font-bold fs-3 mb-4 mt-3">Orders</h2>
            <div className="d-flex das-tabs-wrap p-0 m-0">
                <div className={`order-tab px-4 py-2 rounded-brand border cursor-pointer me-3 ${tabind === 0? 'bg-brand-light': ''}`} onClick={()=> setTabind(0)}>
                    <p className="mb-0 body-font-semibold text-nowrap">All</p>
                </div>
                <div className={`order-tab px-4 py-2 rounded-brand border cursor-pointer me-3 ${tabind === 1? 'bg-brand-light': ''}`} onClick={()=> setTabind(1)}>
                    <p className="mb-0 body-font-semibold text-nowrap"><i className="fab fa-amazon me-2"></i>Amazon</p>
                </div>
                <div className={`order-tab px-4 py-2 rounded-brand border cursor-pointer me-3 ${tabind === 2? 'bg-brand-light': ''}`} onClick={()=> setTabind(2)}>
                    <p className="mb-0 body-font-semibold text-nowrap"><i className="fab fa-whatsapp me-2"></i>Whatsapp</p>
                </div>
                <div className={`order-tab px-4 py-2 rounded-brand border cursor-pointer me-3 ${tabind === 3? 'bg-brand-light': ''}`} onClick={()=> setTabind(3)}>
                    <p className="mb-0 body-font-semibold text-nowrap"><i className="fas fa-globe me-2"></i>Our Website</p>
                </div>
            </div>
            <div className="order-list mt-4 mb-5 px-2">
                <div className="row p-2">
                    <div className="col-md-2">
                        <p className="mb-0 body-font-semibol text-muted">NUMBER</p>
                    </div>
                    <div className="col-md-2">
                        <p className="mb-0 body-font-semibol text-muted">QUANTITY</p>
                    </div>
                    <div className="col-md-3">
                        <p className="mb-0 body-font-semibol text-muted">PRODUCT LINK</p>
                    </div>
                    <div className="col-md-3">
                        <p className="mb-0 body-font-semibol text-muted">PLATFORM</p>
                    </div>
                    <div className="col-md-2">
                        <p className="mb-0 body-font-semibol text-muted">DATE & TIME</p>
                    </div>
                </div>
                {
                    fildata?.map((ord, ind) => (
                        ord.orderdate? (
                            <div key={ind} className="row p-2 py-3">
                                <div className="col-md-2">
                                    <p className="mb-0 body-font-semibol"><span className="mob-order text-muted me-3">User:</span>{ord?.user}</p>
                                </div>
                                <div className="col-md-2">
                                    <p className="mb-0 body-font-semibol"><span className="mob-order text-muted me-3">Quantity:</span>{ord?.quantity}</p>
                                </div>
                                <div className="col-md-3">
                                    <Link to={`/${ord?.productid}/${ord?.productname?.replace(/ /g, '-').replace("/", '_')}`} className="nav-link text-primary mb-0 body-font-semibol"><span className="mob-order text-muted me-3">Product Link:</span>{ord?.productid}</Link>
                                </div>
                                <div className="col-md-3">
                                    <p className="mb-0 body-font-semibol text-capitalize"><span className="mob-order text-muted me-3">Platform:</span>{ord?.platform}</p>
                                </div>
                                <div className="col-md-2">
                                    <p className="mb-0 body-font-semibol"><span className="mob-order text-muted me-3">Order date:</span>{dateFunc(ord?.orderdate)}</p>
                                </div>
                            </div>
                        ): (
                            <div key={ind} className="row p-2 py-3">
                                <div className="col-md-2">
                                    <p className="mb-0 body-font-semibol"><span className="mob-order text-muted me-3">User:</span>{ord?.number}</p>
                                </div>
                                <div className="col-md-2">
                                    <p className="mb-0 body-font-semibol"><span className="mob-order text-muted me-3">Quantity:</span>{ord?.quantity}</p>
                                </div>
                                <div className="col-md-3">
                                    <Link to={`/${ord?.proid}/${ord?.proname?.replace(/ /g, '-').replace("/", '_')}`} className="nav-link text-primary mb-0 body-font-semibol"><span className="mob-order text-muted me-3">Product Link:</span>{ord?.proid}</Link>
                                </div>
                                <div className="col-md-3">
                                    <p className="mb-0 body-font-semibol text-capitalize"><span className="mob-order text-muted me-3">Platform:</span>Website</p>
                                </div>
                                <div className="col-md-2">
                                    <p className="mb-0 body-font-semibol"><span className="mob-order text-muted me-3">Order date:</span>{dateFunc(ord?.cartdate)}</p>
                                </div>
                            </div>
                        )
                    ))
                }
            </div>
        </div>
    </div>
  )
}

export default Orders;