import React, { useState } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import { useAuth } from '../../Context/Authcontext';

function Navbar() {

    const { isloggedin, bardis } = useAuth();

    const [navdis, setNavdis] = useState('none');

  return (
    <div className={`main-navbar ${bardis? 'd-none': 'd-block'}`}>
        <div className="container-fluid d-flex justify-content-between align-items-center bg-brand-light py-2">
            <div className="logo">
                {/* <h2 className="fs-2 body-font-extrabold">HOMEJOY</h2> */}
                <div className="nav-img-wrap py-1">
                    <img src="/assets/logo.png" alt="" className="img-fluid" />
                </div>
            </div>
            <div className="ul-wrap desk-ul d-flex align-items-center">
                <ul className="d-flex p-0 mb-0">
                    <li className="myli mb-0 p-0">
                        <Link to="/" className='nav-link'><p className="mb-0 me-5">Home</p></Link>
                    </li>
                    <li className="myli mb-0 p-0">
                        <Link to="/product" className='nav-link'><p className="mb-0 me-5">Products</p></Link>
                    </li>
                    <li className="myli mb-0 p-0">
                        <Link to="/cart" className='nav-link'><p className="mb-0 me-5">Cart</p></Link>
                    </li>
                    <li className="myli mb-0 p-0">
                        <Link to="/orders" className='nav-link'><p className="mb-0 me-5">Orders</p></Link>
                    </li>
                    <li className="myli mb-0 p-0">
                        <Link to="/history" className='nav-link'><p className="mb-0 me-5">History</p></Link>
                    </li>
                </ul>
            </div>
            <div className="profile-wraper desk-profile d-flex align-items-center">
                <div className="nav-search d-flex align-items-center border border-dark rounded-pill bg-white px-3 py-2 me-2">
                    <input type="text" className="form-control p-0 shadow-none me-2 border-0" placeholder='Search'/>
                    <i className="fas fa-search cursor-pointer"></i>
                </div>
                {
                    isloggedin? (
                        <Link to="/profile" className='nav-link'>
                            <p className="mb-0 body-font-bold btn btn-outline-dark nav-profile"><i className="fas fa-user"></i></p>
                        </Link>
                    ):(
                        <div className="d-flex">
                            <Link to="/login" className='nav-link w-max-content px-3 rounded-pill border border-dark nav-profile me-2'>Log in</Link>
                            <Link to="/signup" className='nav-link w-max-content px-3 rounded-pill bg-dark text-white nav-profile'>Sign up</Link>
                        </div>
                    )
                }
            </div>
            <div className="mob-nav-icon d-flex flex-column align-items-end cursor-pointer" onClick={()=> setNavdis(navdis === 'none'? 'flex': 'none')}>
                <div className={`nav-ham ${navdis === 'flex'? 'cross-class': ''} mb-2`}></div>
                <div className={`nav-ham ${navdis === 'flex'? 'cross-class': ''}`}></div>
            </div>
            <div className="mob-ul" style={{display: navdis}}>
                <ul className="m-0 p-3">
                    <p className="mb-4 fs-3 body-font-extrabold">HOMEJOY</p>
                    <li className="mob-li mb-4">
                        <div className="d-flex justify-content-between align-items-center border border-dark rounded-pill w-100 overflow-hidden px-3 py-2 bg-white">
                            <input type="text" className='from-control shadow-none outline-none border-0 p-0 m-0' placeholder='Search' />
                            <i className="fas fa-search"></i>
                        </div>
                    </li>
                    <li className="mob-li ms-3" onClick={()=> setNavdis('none')}>
                        <Link to="/" className="mb-2 fs-5 nav-link">Home</Link>
                    </li>
                    <li className="mob-li ms-3" onClick={()=> setNavdis('none')}>
                        <Link to="/product" className="mb-2 fs-5 nav-link">Products</Link>
                    </li>
                    <li className="mob-li ms-3" onClick={()=> setNavdis('none')}>
                        <Link to="/cart" className="mb-2 fs-5 nav-link">Cart</Link>
                    </li>
                    <li className="mob-li ms-3" onClick={()=> setNavdis('none')}>
                        <Link to="/orders" className="mb-2 fs-5 nav-link">Orders</Link>
                    </li>
                    <li className="mob-li ms-3" onClick={()=> setNavdis('none')}>
                        <Link to="/history" className="mb-2 fs-5 nav-link">History</Link>
                    </li>
                    {
                        isloggedin? (
                            <li className="mob-li ms-3" onClick={()=> setNavdis('none')}>
                                <Link to="/profile" className="mb-2 fs-5 nav-link">Profile</Link>
                            </li>
                        ): (
                            <li className="mob-li ms-3" onClick={()=> setNavdis('none')}>
                                <Link to="/signup" className="mb-2 fs-5 nav-link">Sign up</Link>
                                <Link to="/login" className="mb-2 fs-5 nav-link">Log in</Link>
                            </li>
                        )
                    }
                </ul>
            </div>
        </div>
    </div>
  )
}

export default Navbar