import React from 'react';
import './Collectioncard.css';
import { Link } from 'react-router-dom';

function Collectioncard(props) {
  return (
    <div className='main-collection-card-wrap'>
        <Link to={`/product/${props.name.toLowerCase()}`} className="nav-link collection-card d-flex align-items-end justify-content-center bg-brand-light2 rounded-brand-lg overflow-hidden" style={{backgroundImage: `url(${props.img})`}}>
            <div className="pb-2 text-center w-max-content">
                <p className="mb-0 collection-btn rounded-brand cursor-pointer px-3 py-2 body-font-bold text-uppercase mx-auto text-nowrap">{props.name}</p>
            </div>
        </Link>
    </div>
  )
}

export default Collectioncard