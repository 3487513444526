import { createContext, useContext, useEffect, useState } from "react";
import cryptoJs from "crypto-js";

const Authcontext = createContext();

export const AuthPovider = ({ children }) => {

    const [userdata, setUserdata] = useState({});
    const [isloggedin, setIsloggedin] = useState(false);
    const [bardis, setBardis] = useState(false);
    const [isadmin, setIsadmin] = useState(false);

    useEffect(()=> {
        if(window.location.href.includes('dashboard')){
            setBardis(true);
        }else{
            setBardis(false)
        }
    }, []);

    useEffect(()=> {
        if(localStorage.getItem("adlocprodat")){
          setIsadmin(true);
        }
    }, []);

    const key = "ad8f89zcviadsf89tjjqwe79kfjgj794"

    const encryptData = (dat, key) => {
        return cryptoJs.AES.encrypt(JSON.stringify(dat), key).toString();
    };

    const decryptData = (dat, key) => {
        const bytes = cryptoJs.AES.decrypt(dat, key);
        return JSON.parse(bytes.toString(cryptoJs.enc.Utf8));
    }

    const login = (data) => {
        setIsloggedin(true);
        setUserdata(data);
        const encrydat = encryptData(data, key);
        localStorage.setItem("locprodat", encrydat);
    }

    const logout = () => {
        setIsloggedin(false);
        setUserdata(null);
        localStorage.clear();
    }

    useEffect(()=> {
        const locdat = localStorage.getItem('locprodat')
        if(locdat){
            const dedata = decryptData(locdat, key);
            setUserdata(dedata);
            setIsloggedin(true);
            fetch(`https://homejoy.onrender.com/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({number: dedata.number, password: dedata.password})
            }).then(res => res.json()).then(data => {
                if(data.success){
                    setUserdata(data.pro);
                }else{
                    console.log('success is not true in context login ', data);
                }
            }).catch(err => console.log('error occured while getting the data of the user in context ', err));
        }
    }, []);

    const splitFunc = (str)=> {
        const imgFst = str.split(',');
        console.log(imgFst)
        return imgFst;
    }
    
    return(
        <Authcontext.Provider value={{userdata, isloggedin, bardis, isadmin, key, setUserdata, setIsloggedin, login, logout, encryptData, decryptData, setBardis, splitFunc, setIsadmin}}>
            {children}
        </Authcontext.Provider>
    )

};

export const useAuth = () => {
    return useContext(Authcontext);
}