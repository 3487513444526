import React, { useEffect, useState } from 'react';
import './Editprofile.css';
import { useAuth } from '../../Context/Authcontext';
import { useNavigate } from 'react-router-dom';

function Editprofile() {

    const { login, userdata } = useAuth();
    const navigate = useNavigate();

    useEffect(() => window.scrollTo(0, 0), []);

    const [errormsg, setErrormsg] = useState("");
    const [successmsg, setSuccessmsg] = useState("");
    const [loaderdis, setLoaderdis] = useState("none");
    const [formdata, setFormdata] = useState({
        name: "",
        number: "",
        email: "",
        address: [""]
    });

    useEffect(() => {
        const editData = { name: "", email: "", number: "", address: [""] };
        if (userdata) {
            editData.name = userdata?.name || "";
            editData.email = userdata?.email || "";
            editData.number = userdata?.number || "";
            editData.address = userdata?.address?.length ? userdata?.address : [""];
        }
        setFormdata(editData);
    }, [userdata]);

    const handleAddressChange = (index, value) => {
        const newAddresses = [...formdata.address];
        newAddresses[index] = value;
        setFormdata((prev) => ({ ...prev, address: newAddresses }));
    };

    const addAddressField = () => {
        setFormdata((prev) => ({
            ...prev,
            address: [...prev.address, ""]
        }));
    };

    const removeAddressField = (index) => {
        const newAddresses = [...formdata.address];
        newAddresses.splice(index, 1);
        setFormdata((prev) => ({ ...prev, address: newAddresses }));
    };

    const signupFunc = () => {
        let isReady = false;

        if (formdata?.name === "" || formdata?.name?.length < 2) {
            setErrormsg("Please enter your name!");
        } else if (formdata?.number === "" || formdata?.number?.length !== 10) {
            setErrormsg("Please provide a valid phone number");
        } else if (formdata?.email === "" || !formdata?.email?.includes("@") || !formdata?.email?.includes(".")) {
            setErrormsg("Please provide a valid email address");
        } else if (formdata?.address.some(ad => ad === "" || ad.length < 10)) {
            setErrormsg("Please provide a valid address");
        } else {
            isReady = true;
        }

        if (isReady) {
            setLoaderdis('flex');
            fetch(`https://homejoy.onrender.com/edituser/${userdata?._id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify(formdata)
            }).then(res => res.json()).then(data => {
                console.log(data);
                if (data.success) {
                    setSuccessmsg(data.msg);
                    setLoaderdis("none");
                    login(data.pro);
                    navigate(-1);
                } else {
                    setErrormsg(data.msg);
                    console.log(data);
                    setLoaderdis("none");
                }
            }).catch(err => console.log('error occurred while signing up ', err));
        }
    };

    const crossFunc = () => {
        setSuccessmsg("");
        setErrormsg("");
    };

    useEffect(() => {
        setTimeout(() => {
            setErrormsg("");
            setSuccessmsg("");
        }, 3000);
    }, [errormsg, successmsg]);

    return (
        <div className='main-signup py-5'>
            <div className="container">
                <div className="form-container shadow py-4 pb-4 px-3 mx-auto mb-5">
                    <h2 className="fs-4 text-center body-font-extrabold mb-4">Edit Profile</h2>
                    <p className="mb-2 body-font-medium fs-5 ps-2">Name</p>
                    <input type="text" name='name' id='name' className='form-control shadow-none outline-none rounded-brand border border-dark mb-4 text-capitalize' value={formdata?.name} onChange={(e) => setFormdata((x) => ({ ...x, name: e.target.value }))} placeholder='Enter your name' />
                    <p className="mb-2 body-font-medium fs-5 ps-2">Phone No.</p>
                    <input type="tel" name='number' id='number' className='form-control shadow-none outline-none rounded-brand border border-dark mb-4 text-capitalize' value={formdata?.number} onChange={(e) => setFormdata((x) => ({ ...x, number: e.target.value }))} placeholder='Enter your number' />
                    <p className="mb-2 body-font-medium fs-5 ps-2">Email ID</p>
                    <input type="mail" name='email' id='email' className='form-control shadow-none outline-none rounded-brand border border-dark mb-4' value={formdata?.email} onChange={(e) => setFormdata((x) => ({ ...x, email: e.target.value }))} placeholder='Enter your email' />
                    
                    <p className="mb-2 body-font-medium fs-5 ps-2">Address</p>
                    {
                        formdata?.address?.map((ad, index) => (
                            <div key={index} className="mb-3 d-flex">
                                <input
                                    type="text"
                                    className='form-control shadow-none outline-none rounded-brand border border-dark text-capitalize me-2'
                                    value={ad}
                                    onChange={(e) => handleAddressChange(index, e.target.value)}
                                    placeholder='Enter your address'
                                />
                                {formdata.address.length > 1 && (
                                    <button type="button" className="btn border border-dark rounded-brand-sm" onClick={() => removeAddressField(index)}><i className="fas fa-trash-alt"></i></button>
                                )}
                            </div>
                        ))
                    }
                    <button type="button" className="btn rounded-brand-sm border border-dark mb-0" onClick={addAddressField}>+ Add Another Address</button>

                    <p className="mb-0 px-2 mt-5">
                        <button className='btn btn-dark px-4 py-2 rounded-pill letter-spacing w-100 fs-5' onClick={signupFunc}>UPDATE PROFILE</button>
                    </p>
                </div>
            </div>

            <div className="error-pop-wrap p-2" style={{ display: errormsg === "" ? "none" : "block" }}>
                <div className="popError-wrap mx-auto px-2 py-1 border-bottom border-danger border-2 shadow">
                    <div className="bg-white d-flex align-items-center justify-content-between px-2 py-3">
                        <p className="mb-0 text-danger body-font-semibold ">{errormsg}</p>
                        <p className="mb-0 p-0 text-danger px-3 cursor-pointer" onClick={crossFunc}><i className="fas fa-times"></i></p>
                    </div>
                </div>
            </div>

            <div className="error-pop-wrap p-2" style={{ display: successmsg === "" ? "none" : "block" }}>
                <div className="popError-wrap mx-auto px-2 py-1 border-bottom border-success border-2 shadow">
                    <div className="bg-white d-flex align-items-center justify-content-between px-2 py-3">
                        <p className="mb-0 text-success body-font-semibold ">{successmsg}</p>
                        <p className="mb-0 p-0 text-success px-3 cursor-pointer" onClick={crossFunc}><i className="fas fa-times"></i></p>
                    </div>
                </div>
            </div>

            <div className="full-overlay" style={{ display: loaderdis }}>
                <div className="loader-wrap rounded-brand p-4">
                    <div className="loader-roundedr"></div>
                </div>
            </div>

        </div>
    );
}

export default Editprofile;
