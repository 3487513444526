import React, { useEffect } from 'react';
import './Confirm.css';
import { Link } from 'react-router-dom';
import { useAuth } from '../../Context/Authcontext';

function Confirm() {

    const {userdata} = useAuth();

    useEffect(() => window.scrollTo(0,0), []);

  return (
    <div className='main-confirm'>
        <div className="contianer-fluid py-5 p-2">
            <div className="conf-fiv d-flex flex-column align-items-center justify-content-center border border-dark rounded-brand p-3 mx-auto">
                <div className="logo text-center">
                    <p className="check-wrap bg-success-subtle mb-0 text-center"><i className="fas fa-check text-success fs-1"></i></p>
                </div>
                <div className="texts mt-4">
                    <h2 className="fs-3 body-font-bold text-center mb-0">ORDERED SUCCESSFULLY</h2>
                    <p className="mb-0 text-muted text-center fs-sm">Your order was placed successfully</p>
                    <p className="mb-0 text-muted text-center">Your Order ID: <span className='body-font-medium'>{userdata?.order[userdata?.order?.length - 1]}</span></p>
                </div>
            </div>
            <div className="conf-fiv d-flex flex-column align-items-center justify-content-center border border-dark rounded-brand p-3 mt-4 mx-auto">
                <div className="texts">
                    <h2 className="fs-5 text-center mb-0 letter-spacing">THANK YOU FOR CHOOSING US!</h2>
                    {/* <p className="mb-0 text-muted text-center fs-sm">Your order was placed successfully</p> */}
                </div>
            </div>
            <div className="text-center mt-4">
                <Link to='/' className="btn btn-outline-dark px-4 py-2 letter-spacing body-font-light">HOME</Link>
                <Link to='/product' className="btn btn-dark px-4 py-2 letter-spacing body-font-light ms-2">EPLORE MORE</Link>
            </div>
        </div>
    </div>
  )
}

export default Confirm