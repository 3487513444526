import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='content-fluid pt-5 px-2 pb-3 bg-dark text-white'>
        <div className="container">
            <div className="row p-0">
                <div className="com-md-12">
                    {/* <h2 className="fs-1 body-font-bold text-center mb-5">HOMEJOY</h2> */}
                    <div className="footer-img-wrap pb-5 mx-auto">
                        <img src="/assets/footer-logo.png" alt="" className="img-fluid" />
                    </div>
                </div>
                <div className="col-md-3 mb-4">
                    <h2 className="text-center body-font-bold fs-5 mb-3">NAVIGATION LINKS</h2>
                    <Link to="/" className="nav-link text-center mb-2">HOME</Link>
                    <Link to="/product" className="nav-link text-center mb-2">PRODUCTS</Link>
                    <Link to="/" className="nav-link text-center mb-2">HSITORY</Link>
                    <Link to="/" className="nav-link text-center mb-2">ABOUT</Link>
                    <Link to="/" className="nav-link text-center mb-2">CONTACT</Link>
                </div>
                <div className="col-md-3 mb-4">
                    <h2 className="text-center body-font-bold fs-5 mb-3">POLICIES</h2>
                    <Link to="/terms-and-conditions" className="nav-link text-center mb-2">TERMS & CONDITIONS</Link>
                    <Link to="/privacy-policy" className="nav-link text-center mb-2">PRIVACY POLICY</Link>
                    <Link to="/return-and-refund-policy" className="nav-link text-center mb-2">RETURN & REFUND POLICY</Link>
                    <Link to="/user-generated-content-policy" className="nav-link text-center mb-2">USER GENERATED CONTENT POLICY</Link>
                    <Link to="/disclaimer" className="nav-link text-center mb-2">DISCLAIMER</Link>
                </div>
                <div className="col-md-3 mb-4">
                    <h2 className="text-center body-font-bold fs-5 mb-3">BLOGS</h2>
                    <Link to="/terms-and-conditions" className="nav-link text-center mb-2">TOP PRODUCTS OF HOMEJOY</Link>
                    <Link to="/privacy-policy" className="nav-link text-center mb-2">HOW TO KEEP YOUR BAGS SAFE</Link>
                    <Link to="/return-and-refund-policy" className="nav-link text-center mb-2">HOW TO CLEAN HANDBAGS</Link>
                    <Link to="/user-generated-content-policy" className="nav-link text-center mb-2">TYPES OF HANDBAGS</Link>
                    <Link to="/disclaimer" className="nav-link text-center mb-2">PURPOSE OF DIFFERENT BAGS</Link>
                </div>
                <div className="col-md-3 mb-4">
                    <h2 className="text-center body-font-bold fs-5 mb-3">CONTACT</h2>
                    <Link to="/terms-and-conditions" className="nav-link text-center mb-2"><i className="fas fa-phone-alt me-2"></i> +91 09876 54321</Link>
                    <Link to="/terms-and-conditions" className="nav-link text-center mb-2"><i className="fas fa-phone-alt me-2"></i> +91 12345 67890</Link>
                    <Link to="/return-and-refund-policy" className="nav-link text-center mb-2"><i className="fas fa-envelope me-2"></i> Email@gmail.com</Link>
                    <Link to="/user-generated-content-policy" className="nav-link text-center mb-2"><i className="fas fa-map-marker-alt me-2"></i> Lorem ipsum dolor sit amet consectetur.</Link>
                </div>
                <div className="col-md-12 text-center mt-5">
                    <p className="text-center fs-4">Follow Us</p>
                    <p className="mb-0">
                        <a href="/" className="nav-link d-inline"><i className="fab fa-instagram fs-2 me-4"></i></a>
                        <a href="/" className="nav-link d-inline"><i className="fab fa-facebook fs-2 me-4"></i></a>
                        <a href="/" className="nav-link d-inline"><i className="fab fa-twitter fs-2 me-4"></i></a>
                        <a href="/" className="nav-link d-inline"><i className="fab fa-linkedin fs-2 me-4"></i></a>
                        <a href="/" className="nav-link d-inline"><i className="fab fa-snapchat fs-2"></i></a>
                    </p>
                </div>
            </div>
        </div>
        <div className="container border-top mt-5 pb-5 pt-2 d-flex justify-content-between">
            <p className="text-secondary mb-0">&copy; <span className='body-font-bold'>HOMEJOY</span> 2024</p>
            <p className="text-secondary mb-0 body-font-semibold">ALL RIGHTS RESERVED</p>
        </div>
    </div>
  )
}

export default Footer;