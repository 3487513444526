import React, { useEffect } from 'react';
import './Userpolicy.css'

function Userpolicy() {

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <div className='container my-5'>
      <h2 className="body-font-bold mb-4 fs-3">User Generated Content Policy</h2>
      <p className="mb-4">
        At HOMEJOY, we value and encourage the creativity and contributions of our customers. Whether it’s through product reviews, social media posts, or other content, we appreciate your input. This User Generated Content (UGC) Policy outlines the guidelines and rules for submitting content to our website or social media channels. By submitting content, you agree to this policy.
      </p>
      <div className="content">
        <ol className='m-0'>
          <li className='mb-4'>
            <p className="fs-5 body-font-medium">Scope of Policy</p>
            <ol className='m-0' type='I'>
              <li>
                <p className="text-muted">
                  <span className='body-font-medium text-black'>Content Types:</span> This policy applies to all forms of user-generated content, including but not limited to.
                </p>
                <ol className='m-0' type='i'>
                  <li>
                    <p className='mb-2 text-muted'>Product reviews and ratings</p>
                  </li>
                  <li>
                    <p className='mb-2 text-muted'>Comments and testimonials</p>
                  </li>
                  <li>
                    <p className='mb-2 text-muted'>Photos, videos, and other multimedia</p>
                  </li>
                  <li>
                    <p className='mb-2 text-muted'>Social media posts tagged with our brand or using our hashtags</p>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li className='mb-4'>
            <p className="fs-5 body-font-medium">Submission Guidelines</p>
            <ol className='m-0' type='I'>
              <li>
                <p className="text-muted">
                  <span className='body-font-medium text-black'>Originality:</span> All content you submit must be your original work. By submitting content, you confirm that you own all rights to the content or have obtained all necessary permissions from any third parties involved.
                </p>
              </li>
              <li>
                <p className="text-muted">
                  <span className='body-font-medium text-black'>Appropriateness:</span> Content must be appropriate for all audiences and must not contain any offensive, abusive, defamatory, or otherwise inappropriate material. This includes, but is not limited to, content that is obscene, hateful, discriminatory, or violates any laws.
                </p>
              </li>
              <li>
                <p className="text-muted">
                  <span className='body-font-medium text-black'>Accuracy:</span> Please ensure that any claims, opinions, or statements made in your content are accurate and reflect your honest experience or views.
                </p>
              </li>
              <li>
                <p className="text-muted">
                  <span className='body-font-medium text-black'>No Infringement:</span> Your content must not infringe on the intellectual property rights, privacy, or other rights of any third party. This includes, but is not limited to, the use of copyrighted material, trademarks, or personal likenesses without permission.
                </p>
              </li>
            </ol>
          </li>
          <li className='mb-4'>
            <p className="fs-5 body-font-medium">Rights and Usage</p>
            <ol className='m-0' type='I'>
              <li>
                <p className="text-muted">
                  <span className='body-font-medium text-black'>License:</span> By submitting content, you grant HOMEJOY a non-exclusive, royalty-free, perpetual, irrevocable, and worldwide license to use, reproduce, modify, adapt, publish, translate, distribute, and display the content in any media, including but not limited to our website, social media channels, marketing materials, and advertisements.
                </p>
              </li>
              <li>
                <p className="text-muted">
                  <span className='body-font-medium text-black'>Attribution:</span> While we are not obligated to provide credit, we may choose to attribute the content to you by including your name, username, or other identifying information.
                </p>
              </li>
              <li>
                <p className="text-muted">
                  <span className='body-font-medium text-black'>Editing:</span> HOMEJOY reserves the right to edit or remove any content submitted by users for any reason, including content that violates this policy or does not meet our standards.
                </p>
              </li>
            </ol>
          </li>
          <li className='mb-4'>
            <p className="fs-5 body-font-medium">Moderation</p>
            <ol className='m-0' type='I'>
              <li>
                <p className="text-muted">
                  <span className='body-font-medium text-black'>Review Process:</span> All user-generated content submitted to our website may be reviewed by our team before it is published. We reserve the right to reject or remove any content that we determine, in our sole discretion, to be inappropriate or in violation of this policy.
                </p>
              </li>
              <li>
                <p className="text-muted">
                  <span className='body-font-medium text-black'>Reporting Violations:</span> If you believe that any content on our website violates this policy or your rights, please contact us at email@gmail.com with details. We will review the reported content and take appropriate action.
                </p>
              </li>
            </ol>
          </li>
          <li className='mb-4'>
            <p className="fs-5 body-font-medium">Liability</p>
            <ol className='m-0' type='I'>
              <li>
                <p className="text-muted">
                  <span className='body-font-medium text-black'>User Responsibility:</span> You are solely responsible for the content you submit. HOMEJOY is not liable for any content posted by users or any loss, damage, or harm resulting from your submission of content.
                </p>
              </li>
              <li>
                <p className="text-muted">
                  <span className='body-font-medium text-black'>Indemnification:</span> You agree to indemnify, defend, and hold harmless HOMEJOY and its affiliates from any claims, damages, losses, liabilities, costs, and expenses arising from your content submission, including any third-party claims of intellectual property infringement or violation of rights.
                </p>
              </li>
            </ol>
          </li>
          <li className='mb-4'>
            <p className="fs-5 body-font-medium">Changes to This Policy</p>
            <p className="text-muted">
              We reserve the right to modify this User Generated Content Policy at any time. Any changes will be effective immediately upon posting on our website with an updated "Last Updated" date. Your continued submission of content after any changes constitutes your acceptance of the new terms.
            </p>
          </li>
          <li className='mb-4'>
            <p className="fs-5 body-font-medium">Contact Information</p>
            <p className="text-muted">
              If you have any questions or concerns regarding these Terms and Conditions, please contact us at:
            </p>
            <p className="mb-0">HOMEJOY Customer Service</p>
            <p className="mb-0">Email: <a href="mailto:email@gmail.com" className="nav-link d-inline text-primary">email@gmail.com</a></p>
            <p className="mb-0">Phone no.: <a href="tel:+910987654321" className="nav-link d-inline text-primary">+91 09876 54321</a></p>
          </li>
        </ol>
      </div>
    </div>
  )
}

export default Userpolicy