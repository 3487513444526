import React, { useEffect } from 'react';
import './Returnpolicy.css';

function Returnpolicy() {

    useEffect(()=> window.scrollTo(0, 0), []);

  return (
    <div className='container my-5'>
        <h2 className="body-font-bold mb-4 fs-3">Return and Refund Policy</h2>
        <p className="mb-4">
            At HOMEJOY, we want you to be completely satisfied with your purchase. If for any reason you are not satisfied, we offer a straightforward return and refund process. Please read the following policy carefully to understand your rights and obligations.
        </p>
        <div className="content">
            <ol className='m-0'>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">Return Eligibility</p>
                    <ol className='m-0' type='I'>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Timeframe:</span> You may return any unworn, unused, and undamaged item(s) within 5 days of the delivery date for a full refund or exchange.
                            </p>
                        </li>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Condition:</span> To be eligible for a return, the item(s) must be in their original condition, including tags, packaging, and any accessories that were included. Handbags that show signs of wear, damage, or alterations will not be accepted.
                            </p>
                        </li>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Non-Returnable Items:</span> The following items are non-returnable.
                            </p>
                            <ol className='m-0' type='i'>
                                <li>
                                    <p className="mb-2 text-muted">Gift cards</p>
                                </li>
                                <li>
                                    <p className="mb-2 text-muted">Sale items or items purchased with a discount code (unless they are defective)</p>
                                </li>
                                <li>
                                    <p className="mb-2 text-muted">Customized or personalized items</p>
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">Return Process</p>
                    <ol className='m-0' type='I'>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Initiate a Return:</span> To start a return, please contact our Customer Service team at email@gmail.com with your order number, the item(s) you wish to return, and the reason for the return. We will provide you with detailed instructions on how to proceed.
                            </p>
                        </li>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Return Shipping:</span> Customers are responsible for the cost of return shipping unless the return is due to a defect or error on our part. We recommend using a trackable shipping service or purchasing shipping insurance, as we are not responsible for items lost during return shipping.
                            </p>
                        </li>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Return Address:</span> Once your return is authorized, please ship the item(s) to the address provided by our Customer Service team.
                            </p>
                        </li>
                    </ol>
                </li>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">Refunds</p>
                    <ol className='m-0' type='I'>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Processing Time:</span> Once we receive and inspect your return, we will notify you of the approval or rejection of your refund. If approved, your refund will be processed, and a credit will automatically be applied to your original method of payment within 5-10 business days.
                            </p>
                        </li>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Original Shipping Costs:</span> Original shipping charges are non-refundable unless the return is due to a defective product or an error on our part.
                            </p>
                        </li>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Late or Missing Refunds:</span> If you haven’t received a refund within the expected timeframe, please first check your bank account and contact your credit card company or bank, as it may take some time before your refund is officially posted. If you still have not received your refund, please contact us at email@gmail.com.
                            </p>
                        </li>
                    </ol>
                </li>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">Exchanges</p>
                    <ol className='m-0' type='I'>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Eligibility:</span> We only replace items if they are defective or damaged. If you need to exchange it for the same item, please contact us at email@gmail.com.
                            </p>
                        </li>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Exchange Process:</span> Once your exchange is approved, we will ship the replacement item to you at no additional cost. If the replacement item is out of stock, you may choose a refund or select a different item of equal value.
                            </p>
                        </li>
                    </ol>
                </li>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">Defective or Incorrect Items</p>
                    <p className="text-muted">
                        Reporting Issues: If you receive a defective or incorrect item, please contact us immediately at email@gmail.com with your order number, a description of the issue, and photos if applicable. We will provide instructions for returning the item, and you will be eligible for a full refund or exchange, including return shipping costs.
                    </p>
                </li>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">Cancellations</p>
                    <p className="text-muted">
                        Order Cancellations: If you need to cancel your order, please contact us as soon as possible at email@gmail.com. If your order has not yet been shipped, we will cancel it and issue a full refund. If your order has already been shipped, you may return the item(s) in accordance with our return policy.
                    </p>
                </li>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">Contact Information</p>
                    <p className="text-muted">
                        If you have any questions or concerns regarding these Terms and Conditions, please contact us at:
                    </p>
                    <p className="mb-0">HOMEJOY Customer Service</p>
                    <p className="mb-0">Email: <a href="mailto:email@gmail.com" className="nav-link d-inline text-primary">email@gmail.com</a></p>
                    <p className="mb-0">Phone no.: <a href="tel:+910987654321" className="nav-link d-inline text-primary">+91 09876 54321</a></p>
                </li>
            </ol>
        </div>
    </div>
  )
}

export default Returnpolicy